import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import { tclass } from './index';

class Checkbox extends PureComponent {
    static defaultProps = {
        disabled: false,
        is_check: false,
    };

    render() {
        const id = `check_box_${md5(Math.random())}`;
        return (
            <React.Fragment>
                <div className={tclass('nxt_checkbox')}>
                    <label htmlFor={id} className={tclass('label_checkbox')}>
                        <input id={id} type="checkbox" disabled={this.props.disabled} checked={this.props.is_check} onChange={this.props.onClick} />
                        <span className={tclass('checkmark')} />
                        {this.props.children && (
                            <span className={tclass('title')}>
                                {this.props.children}
                            </span>
                        )}
                    </label>
                </div>
            </React.Fragment>
        );
    }
}

Checkbox.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.string,
    is_check: PropTypes.bool,
};

export default Checkbox;
