import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import Body from '../layout/Body';
import loading from './Loading';

const EmbedRegister = Loadable({ loader: () => import('../page/embed/register'), loading });
const Login = Loadable({ loader: () => import('../page/user/login/login'), loading });
const LoginToken = Loadable({ loader: () => import('../page/user/login/LoginToken'), loading });
const Register = Loadable({ loader: () => import('../page/user/register/register'), loading });
const Logout = Loadable({ loader: () => import('../page/user/logout/logout'), loading });
const Forgot = Loadable({ loader: () => import('../page/user/forgot'), loading });
// const Fcm = Loadable({ loader: () => import('../page/bpos/aff'), loading });

class TRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BrowserRouter>
                <React.Fragment>
                    <Route exact path="/" component={Body} />
                    <Route path="/login" component={Login} />
                    <Route path="/embed-register" component={EmbedRegister} />
                    <Route path="/login-via-token/:node_name/:token" component={LoginToken} />
                    <Route path="/register" component={Register} />
                    <Route path="/forgot" component={Forgot} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/bpos/aff/:type" component={Body} />
                    <Route path="/aff/:type" component={Body} />
                    <Route path="/fcm/:type" component={Body} />
                    <Route path="/help/:type" component={Body} />
                    <Route path="/policies/:type" component={Body} />
                    <Route path="/report/:type" component={Body} />
                </React.Fragment>
            </BrowserRouter>
        );
    }
}
const mapStateToProps = ({ state, persist }) => ({
    login: state.login,
    userRole: persist.userRole,
    userPermission: persist.userPermission,
});
export default connect(mapStateToProps)(TRouter);
