import React from 'react';
import { tclass } from '../index';

const MenuItemRoot = ((props) => {
    const { keys_root, showChild, k_item, v_item, listMenuChild } = props;
    return (
        <div key={keys_root} className="content_menu_item no-margin">
            <div className="content_menu_item_title no-padding" onClick={() => showChild(k_item)}>
                <div className="content_menu_item_title_txt">{v_item.title} <i className={tclass('icons8-arrow-pointing-to-right ic_menu', { drop_down: v_item.active })} /></div>
            </div>
            <div className={tclass('content_menu_item_child', { show: v_item.active })}>
                {listMenuChild(k_item, v_item)}
            </div>
        </div>
    );
});

export default React.memo(MenuItemRoot);
