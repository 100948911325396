/* eslint-disable react/require-default-props */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { omit, isEmpty } from 'lodash';
import { tclass } from './index';

class Modal extends PureComponent {
    static defaultProps = {
        size: 'md',
    }

    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
        };
    }

    componentDidMount() {
        const { visible } = this.props;
        if (visible === true) this.setState({ hidden: false });
    }

    componentWillReceiveProps(nextProps) {
        const self = this;
        if (nextProps.visible === true) this.setState({ hidden: false });
        if (nextProps.visible === false) {
            setTimeout(() => {
                self.setState({ hidden: true });
            }, 200);
        }
    }

    render() {
        const { hidden } = this.state;
        const propss = Object.assign({}, this.props);

        let clsName = tclass('modal animated', {
            fadeInDownBig: propss.visible,
            fadeOutUpBig: !propss.visible,
            show_md: propss.visible,
            hide_md: hidden,
        });
        if (propss.className) clsName += ` ${tclass(propss.className)}`;
        let w = '1';
        const regex = new RegExp(/^\d+%$/gm);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(parseInt(propss.size, 10)) || regex.test(propss.size)) {
            w = propss.size;
        }

        return (
            <React.Fragment>
                <div className={tclass({ md_overlay: propss.visible })} />
                <div {...omit(propss, ['className', 'visible', 'onOk', 'onCancel', 'txtCancel', 'txtOk', 'onDelete', 'txtdelete', 'txtContinue', 'onContinue'])} className={clsName}>
                    <div
                        className={tclass('modal-dialog', {
                            'modal-md': propss.size === 'md',
                            'modal-sm': propss.size === 'sm',
                            'modal-lg': propss.size === 'lg',
                            'modal-xl': propss.size === 'xl',
                        })}
                        style={{ width: w }}
                    >
                        <div className={tclass('modal-content')}>
                            <div className={tclass('modal-header')}>
                                <button type="button" className={tclass('close')} onClick={propss.onCancel ? propss.onCancel : undefined}>
                                    <i className={tclass('icons8-close-button')} />
                                </button>
                                <h4 className={tclass('modal-title')}>{propss.title ? propss.title : 'Tiêu đề'}</h4>
                            </div>
                            <div className={tclass('modal-body')}>
                                {propss.children}
                            </div>
                            <div className={tclass('modal-footer')} style={{ display: (isEmpty(propss.txtCancel) && isEmpty(propss.txtOk) && isEmpty(propss.txtContinue) && isEmpty(propss.txtdelete)) ? 'none' : 'flex' }}>
                                {propss.txtCancel
                                    && <button type="button" className={tclass('btn btn-default')} onClick={propss.onCancel ? propss.onCancel : undefined}> {propss.txtCancel ? propss.txtCancel : 'Đóng'}</button>
                                }
                                {propss.txtOk
                                    && <button type="button" className={tclass('btn btn-primary')} data-dismiss="modal" onClick={propss.onOk}>{propss.txtOk ? propss.txtOk : 'Đồng ý'}</button>
                                }
                                {propss.txtContinue
                                    && <button type="button" className={tclass('btn btn-primary')} data-dismiss="modal" onClick={propss.onContinue}>{propss.txtContinue ? propss.txtContinue : 'Tiếp tục'}</button>
                                }
                                {propss.txtdelete
                                    && <button type="button" className={tclass('btn btn-warning')} data-dismiss="modal" onClick={propss.onDelete}>{propss.txtDelete ? propss.txtdelete : 'Xoá'}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Modal.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    txtCancel: PropTypes.string,
    txtOk: PropTypes.string,
    txtdelete: PropTypes.string,
    txtContinue: PropTypes.string,
    size: PropTypes.string,
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    onContinue: PropTypes.func,
};

export default Modal;
