import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import { RadioGroup as RadioGroups } from 'react-icheck';
import PropTypes from 'prop-types';
import Radio from './Radio';

class RadioGroup extends PureComponent {
    static defaultProps = {
        source: [],
        value: 0,
        name: 'nxt',
    }

    renderList(source) {
        const htm = source.map((v, k) => (
            <Radio
                key={k}
                value={v.value}
                radioClass="iradio_flat-grey"
                increaseArea={v.increaseArea}
                label={v.label}
                disabled={v.disabled}
            />
        ));
        return htm;
    }

    render() {
        const propss = omit(this.props, ['source', 'value', 'name']);
        const { source, value, name } = this.props;
        return (
            <RadioGroups name={name} value={value} {...propss}>
                {this.renderList(source)}
            </RadioGroups>
        );
    }
}
/* eslint-disable*/
RadioGroup.propTypes = {
    source: PropTypes.array,
    value: PropTypes.any,
    name: PropTypes.string,
};
/* eslint-enable */

export default RadioGroup;
