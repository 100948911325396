import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, omit } from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Input, tclass, Select, Button } from './index';

class FormFilter extends PureComponent {
    static defaultProps = {
        require: false,
        format: 'text',
        getRef: null,
        readonly: false,
        limit: 20,
        offset: 0,
    }

    constructor(props) {
        super(props);
        this.state = {
            err: props.error,
            options: props.options,
            active: false,
            search: {},
        };
        if (props.getRef !== null) this.myRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.error !== prevState.err) {
            return { err: nextProps.error };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.getRef !== null) this.props.getRef(this.myRef);
    }

    onChangeLocal = (e) => {
        // const { value } = e.target;

        // if (this.props.format === 'price') {
        //     if (isEmpty(value)) value = 0;
        //     e.target.value_format = FormatPrice(value);
        //     e.target.value_number = this.format_number(value);
        // } else if (this.props.format === 'quantity') {
        //     if (isEmpty(value)) value = 0;
        //     //Kiểm tra xem nó có mấy ký tự.
        //     e.target.value_format = this.ceilCustom(value);
        //     e.target.value_number = this.ceilCustom(value);
        //     e.target.value = this.ceilCustom(value);
        // }
        if (this.props.onChange) this.props.onChange(e);
    }

    // format_number(string) {
    //     return intval(replace(string, /\./g, ''));
    // }

    //Làm tròn số đến hàng thứ 2
    // ceilCustom(float_numss) {
    //     let float_num = float_numss;
    //     const n = parseFloat(float_numss);
    //     float_num = Math.round(n * 100) / 100;
    //     return float_num;
    // }

    onChangeSelect(type, e) {
        const { search } = this.state;
        search[type].value = e.value;
        this.setState({ search });
    }

    changeFilter() {
        const { active } = this.state;
        this.setState({ active: !active });
    }

    renderSelect(option) {
        const options = [];
        if (!isEmpty(option)) {
            option.forEach((v) => {
                const tmp = {
                    value: v.value,
                    label: v.label,
                };
                options.push(tmp);
            });
        }
        return options;
    }

    renderOptions() {
        const { options } = this.state;
        const htm = options.map((v, k) => (
            <React.Fragment key={k}>
                {v.type === 'text' && (
                    <div className="col-xl-20 margin-bottom-15">
                        <Input
                            // onChange={(e) => onChangeInput('full_name', e)}
                            className={tclass('form-control')}
                            type="text"
                            placeholder={v.placeholder}
                            label={v.label}
                        // value={search.full_name.value}
                        />
                    </div>
                )}
                {v.type === 'select' && (
                    <div className="col-xl-20 margin-bottom-15">
                        <label>{v.label}</label>
                        <Select
                            // onChange={(e) => onChangeSelect('province_id', e)}
                            options={this.renderSelect(v.options)}
                            placeholder={v.placeholder}
                        // value={search.province_id.value}
                        />
                    </div>
                )}
                {v.type === 'date' && (
                    <div className="col-xl-20 margin-bottom-15">
                        <label>{v.label}</label>
                        <DatePicker
                            className="form-control"
                            // selected={search.birthday_start.value ? moment(search.birthday_start.value, 'YYYY/MM/DD') : null}
                            //value={search.birthday_start.value !== '' ? moment(search.birthday_start.value, 'YYYY/MM/DD') : ''}
                            // inputProps.value !== '' ? inputProps.value : null
                            // onChange={(e) => handleChangeDate('birthday_start', e)}
                            minDate={moment().subtract(50, 'y')}
                            // maxDate={moment()}
                            dateFormat="DD/MM/YYYY"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                )}
                {v.type === 'datetime' && (
                    <div className="col-xl-20 margin-bottom-15">
                        <label>{v.label}</label>
                        <DatePicker
                            className="form-control"
                            // selected={search.birthday_start.value ? moment(search.birthday_start.value, 'YYYY/MM/DD') : null}
                            //value={search.birthday_start.value !== '' ? moment(search.birthday_start.value, 'YYYY/MM/DD') : ''}
                            // inputProps.value !== '' ? inputProps.value : null
                            // onChange={(e) => handleChangeDate('birthday_start', e)}
                            minDate={moment().subtract(50, 'y')}
                            // maxDate={moment()}
                            dateFormat="DD/MM/YYYY HH:mm"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            showTimeSelect
                            timeFormat="HH:mm"
                            placeholderText="Chọn ngày "
                        />
                    </div>
                )}
            </React.Fragment>
        ));
        return htm;
    }

    render() {
        const { active } = this.state;
        const propss = { ...this.props };

        return (
            <React.Fragment>
                <form style={{ width: '100%' }}>
                    <div className="personnel_list_btn">
                        <div className="col-md-6">
                            <input className="form-control" {...omit(propss, ['require', 'onChange', 'value', 'ref', 'getRef', 'readonly'])} readOnly={propss.readonly} value={propss.value} onChange={this.onChangeLocal} />
                        </div>
                        <div className="col-md-2">
                            <Button className="btn btn-primary" onClick={() => this.changeFilter()}>Lọc</Button>
                        </div>
                    </div>
                    <div className={tclass('personnel_list_form', active ? 'show' : '')}>
                        {this.renderOptions()}
                    </div>
                </form>
            </React.Fragment>
        );
    }
}
/* eslint-disable react/no-unused-prop-types,react/require-default-props*/
FormFilter.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    require: PropTypes.bool,
    readonly: PropTypes.bool,
    format: PropTypes.string,
    onChange: PropTypes.func,
    getRef: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    result: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.any,
};
/* eslint-enable */

export default FormFilter;
