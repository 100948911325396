import * as Types from '../actions';
/* eslint-disable*/
export const selectField = (field) => (dispatch) => {
    dispatch({
        type: Types.SELECT_FIELD,
        field,
    });
    return Promise.resolve();
};

export const unselectField = (field) => (dispatch) => {
    dispatch({
        type: Types.UNSELECT_FIELD,
        field,
    });
    return Promise.resolve();
};

export const addFilter = () => (dispatch) => {
    dispatch({
        type: Types.ADD_FILTER,
    });
    return Promise.resolve();
};


export const removeFilter = (filterId) => (dispatch) => {
    dispatch({
        type: Types.REMOVE_FILTER,
        filterId,
    });
    return Promise.resolve();
};


export const clearFilters = (filterId) => (dispatch) => {
    dispatch({
        type: Types.CLEAR_FILTERS,
    });
    return Promise.resolve();
};


export const changeFilterField = (filterId, fieldName) => (dispatch) => {
    dispatch({
        type: Types.CHANGE_FILTER_FIELD,
        filterId,
        fieldName,
    });
    return Promise.resolve();
};

export const changeFilterOperator = (filterId, operator) => (dispatch) => {
    dispatch({
        type: Types.CHANGE_FILTER_OPERATOR,
        filterId,
        operator,
    });
    return Promise.resolve();
};

export const changeFilterValue = (filterId, value) => (dispatch) => {
    dispatch({
        type: Types.CHANGE_FILTER_VALUE,
        filterId,
        value,
    });
    return Promise.resolve();
};

export const dataFilter = (params) => (dispatch) => {
    dispatch({
        type: Types.DATA_FILTER,
        value: params,
    });
    return Promise.resolve();
};