import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, forEach, includes } from 'lodash';
import waterfall from 'async/waterfall';
import { slide as Menu } from 'react-burger-menu';
import { Avatar, tclass, Link, Button, Select } from '../library';
import SettingProfile from '../../bpos/profile/profile';
import Logout from '../../bpos/profile/Logout';
import { R_THEMES_DEFAULT } from '../../../reducers/actions';
import NavigationLi from './NavigationLi';
import iconEmail from '../../../../style/image/letter.svg';

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visible1: false,
            visible2: false,
            visible3: false,
            active: false,
            activeMenu: false,
            totalCart: 0,
            show_menu: {
                report: false,
                policies: false,
                depot: false,
                setting: false,
                partner: false,
                product: false,
                sell: false,
                EndDay: false,
                ProductReport: false,
                OrderReport: false,
                CustomerReport: false,
                SupplierReport: false,
                SaleReport: false,
            },
            isMyMenuOpen: false,
        };
    }

    changeStatus(act) {
        const { activeMenu } = this.state;
        if (act === 'activeMenu') {
            this.setState({ activeMenu: !activeMenu });
        }
    }

    componentDidMount() {
        const { cart_product } = this.props;
        this.countCartProduct(cart_product);
    }

    componentWillReceiveProps(props) {
        this.countCartProduct(props.cart_product);
    }

    countCartProduct(cart_product) {
        let totalCart = 0;
        const self = this;
        if (!isEmpty(cart_product)) {
            waterfall([
                (callback) => {
                    forEach(cart_product, (v) => {
                        totalCart += parseInt(v.quantity);
                    });
                    callback(null, totalCart);
                },
            ], (err, totalCart) => {
                self.setState({ totalCart });
            });
        } else {
            self.setState({ totalCart });
        }
    }

    onVisibleLocal() {
        const { visible } = this.state;
        this.setState({ visible: !visible });
    }

    onVisible = () => {
        this.onVisibleLocal();
    }

    onVisibleLocal1() {
        const { visible1 } = this.state;
        this.setState({ visible1: !visible1 });
    }

    onVisible1 = () => {
        this.onVisibleLocal1();
    }


    onVisibleLocal3 = () => {
        const { visible3 } = this.state;
        this.setState({ visible3: !visible3 });
    }

    onVisible3 = () => {
        this.onVisibleLocal3();
    }

    changePannel = () => {
        const { active } = this.state;
        this.setState({ active: !active });
    }

    isOdd(i) {
        return i % 2 !== 0;
    }

    onClickColor(themes_color) {
        const { dispatch } = this.props;
        document.body.className = themes_color;
        dispatch({ type: R_THEMES_DEFAULT, payload: themes_color });
    }

    onActiveMenu = (menu) => {
        const { show_menu } = this.state;
        const arr = ['EndDay', 'ProductReport', 'OrderReport', 'CustomerReport', 'SupplierReport', 'SaleReport'];
        const check_report = includes(arr, menu);
        if (!check_report) {
            forEach(show_menu, (value, key) => {
                if (value === true && key !== menu) {
                    show_menu[key] = false
                }
            });
            show_menu[menu] = !show_menu[menu];
            this.setState({ show_menu, isMyMenuOpen: true });
        } else {
            forEach(show_menu, (value, key) => {
                if (value === true && key !== 'report' && key !== menu) {
                    show_menu[key] = false
                }
            });
            show_menu[menu] = !show_menu[menu];
            this.setState({ show_menu, isMyMenuOpen: true });
        }
    }

    onClickNavigation() {
        this.setState({ isMyMenuOpen: false });
        // const { dispatch } = this.props;
        // document.body.className = themes_color;
        // dispatch({ type: R_THEMES_DEFAULT, payload: themes_color });
    }

    optionsDepot() {
        const options = [];
        const { depots } = this.props;
        if (!isEmpty(depots)) {
            depots.forEach((v) => {
                const tmp = {
                    value: v.id,
                    label: v.name,
                };
                options.push(tmp);
            });
        }
        return options;
    }

    renderLi(url) {
        return (<li><Link to={url}>Cuối ngày</Link></li>);
    }

    render() {
        const { profile, depot_current, theme_color, userRole } = this.props;
        const { visible, visible1, show_menu, isMyMenuOpen } = this.state;

        return (
            <React.Fragment>
                <div className={tclass('navigation')}>
                    <div id="menu_mobile" className="hidden-lg visible-md">
                        <Menu bodyClassName="menu_mobile_active" id="stack" isOpen={isMyMenuOpen}>
                            <ul>
                                <li className="depots_mobile">
                                    <Select
                                        value={depot_current}
                                        onChange={(e) => this.onChangeDepot(e)}
                                        options={this.optionsDepot()}
                                    />
                                </li>
                                <li className={tclass('menu_item')}>
                                    <Link to="/aff/Dashboard">Phân phối
                                    </Link>
                                    <i className="icons8-drop-down-arrow" onClick={() => this.onActiveMenu('sell')} />
                                    <ul className={tclass('sub_menu', show_menu.sell ? 'show' : '')}>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/aff/Dashboard" title="Trang chủ" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/aff/Lead" title="Danh sách khách hàng" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/aff/Advertisement" title="Mẫu bài quảng cáo" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/aff/Tracking" title="Link phân phối" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/aff/product-banner" title="Tạo banner HTML " />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/aff/product" title="Sản phẩm" />
                                        </div>
                                    </ul>
                                </li>
                                <li className={tclass('menu_item')}>
                                    <Link to="/report/contract-report">Báo cáo
                                    </Link>
                                    <i className="icons8-drop-down-arrow" onClick={() => this.onActiveMenu('report')} />
                                    <ul className={tclass('sub_menu', show_menu.report ? 'show' : '')}>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/report/contract-report" title="Báo cáo hiệu quả" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/report/statistic-tracking-link" title="Báo cáo chiến dịch" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/report/contract" title="Báo cáo hợp đồng" />
                                        </div>
                                    </ul>
                                </li>
                                <li className={tclass('menu_item')}>
                                    <Link to="/help/product-infomation">Hướng dẫn
                                    </Link>
                                    <i className="icons8-drop-down-arrow" onClick={() => this.onActiveMenu('partner')} />
                                    <ul className={tclass('sub_menu', show_menu.partner ? 'show' : '')}>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/help/product-infomation" title="Tạo tài khoản và đăng nhập" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/help/affiliate" title="Giới thiệu khách hàng" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/help/create-link-affiliate" title="Tạo link phân phối" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/help/change-password" title="Đổi mật khẩu" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/help/list-customer" title="Thông tin khách hàng" />
                                        </div>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/help/list-link-created" title="Danh sách link đã tạo" />
                                        </div>
                                    </ul>
                                </li>
                                <li className={tclass('menu_item')}>
                                    <Link to="/policies/policies">Chính sách & điều khoản <i className="icons8-drop-down-arrow" /></Link>
                                    <i className="icons8-drop-down-arrow" onClick={() => this.onActiveMenu('policies')} />
                                    <ul className={tclass('sub_menu', show_menu.policies ? 'show' : '')}>
                                        <div onClick={() => this.onClickNavigation()}>
                                            <NavigationLi {...this.props} url="/policies/policies" title="Chính sách & điều khoản" />
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                        </Menu>
                    </div>
                    <div className={tclass('navigation_logo')}>
                        <Link to="/aff/Dashboard"><img src="/image/aff/logo_bpos_aff.svg?v=1" alt="BNC Project" className="img-responsive" /></Link>
                    </div>
                    <div className={tclass('navigation_mobile hidden-sm hidden-xs')}>
                        <div className={tclass('navigation_menu')}>
                            <ul>

                                <li className={tclass('menu_item')}>
                                    <Link to="/aff/Dashboard">Phân phối <i className="icons8-drop-down-arrow" /></Link>
                                    <ul className={tclass('sub_menu')}>
                                        {/* <NavigationLi {...this.props} url="/bpos/exchange/order-list" title="Đơn hàng" />
                                        <NavigationLi {...this.props} url="/bpos/exchange/receipts-list" title="Sổ quỹ" />
                                        <NavigationLi {...this.props} url="/bpos/exchange/discount" title="Khuyến mại" /> */}
                                        <NavigationLi {...this.props} url="/aff/Dashboard" title="Trang chủ" />
                                        <NavigationLi {...this.props} url="/aff/Lead" title="Danh sách khách hàng" />
                                        <NavigationLi {...this.props} url="/aff/Advertisement" title="Mẫu bài quảng cáo" />
                                        <NavigationLi {...this.props} url="/aff/Tracking" title="Link phân phối" />
                                        <NavigationLi {...this.props} url="/aff/product-banner" title="Tạo banner HTML " />
                                        <NavigationLi {...this.props} url="/aff/product" title="Sản phẩm" />

                                    </ul>
                                </li>

                                <li className={tclass('menu_item')}>
                                    <Link to="/report/contract-report">Báo cáo <i className="icons8-drop-down-arrow" /></Link>
                                    <ul className={tclass('sub_menu')}>
                                        <NavigationLi {...this.props} url="/report/contract-report" title="Báo cáo hiệu quả" />
                                        <NavigationLi {...this.props} url="/report/statistic-tracking-link" title="Báo cáo chiến dịch" />
                                        <NavigationLi {...this.props} url="/report/contract" title="Báo cáo hợp đồng" />
                                    </ul>
                                </li>
                                <li className={tclass('menu_item')}>
                                    <Link to="/help/product-infomation">Hướng dẫn <i className="icons8-drop-down-arrow" /></Link>
                                    <ul className={tclass('sub_menu')}>
                                        <NavigationLi {...this.props} url="/help/product-infomation" title="Tạo tài khoản và đăng nhập" />
                                        <NavigationLi {...this.props} url="/help/affiliate" title="Giới thiệu khách hàng" />
                                        <NavigationLi {...this.props} url="/help/create-link-affiliate" title="Tạo link phân phối" />
                                        <NavigationLi {...this.props} url="/help/change-password" title="Đổi mật khẩu" />
                                        <NavigationLi {...this.props} url="/help/list-customer" title="Thông tin khách hàng" />
                                        <NavigationLi {...this.props} url="/help/list-link-created" title="Danh sách link đã tạo" />

                                    </ul>
                                </li>
                                <li className={tclass('menu_item')}>
                                    <Link to="/policies/policies">Chính sách & điều khoản <i className="icons8-drop-down-arrow" /></Link>
                                    <ul className={tclass('sub_menu')}>
                                        <NavigationLi {...this.props} url="/policies/policies" title="Chính sách & điều khoản" />
                                    </ul>
                                </li>
                                {/* <li className={tclass('menu_item')}>
                                    <Link to="/fcm/notify">Thông báo firebase<i className="icons8-drop-down-arrow" /></Link>
                                    <ul className={tclass('sub_menu')}>
                                        <NavigationLi {...this.props} url="/fcm/notify" title="Thông báo" />
                                        <NavigationLi {...this.props} url="/fcm/token" title="Danh sách token" />
                                    </ul>
                                </li> */}
                            </ul>
                        </div>

                        <div className={tclass('navigation_change_color')}>
                            <img src="/image/paint-palette.svg" alt="BNC Project" className="img-responsive img-avartar" />
                            <div className={tclass('navigation_change_color_box')}>
                                <p>Màu chủ đạo</p>
                                <ul className="navigation_change_color_box_list row">
                                    <li role="presentation" className={tclass('col-md-4 theme-red', { active: theme_color === 'theme_red' })} onClick={() => this.onClickColor('theme_red')}><span /></li>
                                    <li role="presentation" className={tclass('col-md-4 theme-default', { active: theme_color === 'theme_default' })} onClick={() => this.onClickColor('theme_default')}><span /></li>
                                    <li role="presentation" className={tclass('col-md-4 theme-blue', { active: theme_color === 'theme_blue' })} onClick={() => this.onClickColor('theme_blue')}><span /></li>
                                    <li role="presentation" className={tclass('col-md-4 theme-purple', { active: theme_color === 'theme_purple' })} onClick={() => this.onClickColor('theme_purple')}><span /></li>
                                    <li role="presentation" className={tclass('col-md-4 theme-oranger', { active: theme_color === 'theme_oranger' })} onClick={() => this.onClickColor('theme_oranger')}><span /></li>
                                    <li role="presentation" className={tclass('col-md-4 theme-dark', { active: theme_color === 'theme_dark' })} onClick={() => this.onClickColor('theme_dark')}><span /></li>
                                </ul>
                                <hr />
                                <p className="no-top-space">Chế độ màu</p>
                                <ul className="navigation_themes_color_box_list row">
                                    <li role="presentation" className={tclass('col-md-6 theme-light', { active: theme_color === 'theme_light' })} onClick={() => this.onClickColor('theme_light')}><span>Light</span></li>
                                    <li role="presentation" className={tclass('col-md-6 theme-dark-mode', { active: theme_color === 'theme_dark_mode' })} onClick={() => this.onClickColor('theme_dark_mode')}><span>Dark</span></li>
                                </ul>
                            </div>
                        </div>

                        <div className={tclass('navigation_notification')}>
                            <div className={tclass('navigation_notification_box')}>
                                <div className={tclass('navigation_notification_icon')}>
                                    <i className="la la-bell" />
                                    <span className={tclass('badge')}>0</span>
                                </div>
                                <div className={tclass('navigation_notification_list')}>
                                    <p>Thông báo</p>
                                    <ul className={tclass('navigation_notification_list_main')}>
                                        <li>
                                            <Link to="/help">
                                                <figure className="navigation_notification_list_ava">
                                                    <img src={iconEmail} alt="BNC Project" className="img-responsive img_cover" />
                                                </figure>
                                                <figcaption className="navigation_notification_list_info">
                                                    <p><strong>Thông báo</strong> chưa có thông báo mới.</p>
                                                    <time><i className="la la-clock-o" /> 0 giờ</time>
                                                </figcaption>
                                            </Link>
                                        </li>
                                    </ul>
                                    <p className="navigation_notification_list_see_all text-center"><Link to="/help">Xem tất cả</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={tclass('navigation_setting')}>
                        <ul>
                            <li>
                                <div className={tclass('navigation_setting_user')}>
                                    <div className={tclass('navigation_setting_img')}>
                                        <Avatar src={profile.photo} />
                                    </div>
                                    <span className={tclass('navigation_setting_info')}>
                                        <h3>{!isEmpty(profile.full_name) ? profile.full_name : 'New member'}</h3>
                                        <p>{!isEmpty(userRole) ? userRole[0].role_name : '--none--'}</p>
                                        <i className="la la-chevron-circle-down" />
                                    </span>
                                </div>
                                <ul className={tclass('navigation_setting_sub')}>
                                    <li className={tclass('navigation_setting_user_name')}>
                                        <Button className="btn btn-none" onClick={() => this.onVisibleLocal()}>
                                            <i className="la la-user" /> {!isEmpty(profile.full_name) ? profile.full_name : 'New member'}
                                        </Button>
                                    </li>
                                    <li className={tclass('navigation_setting_user_info')}>
                                        <Button className="btn btn-none" onClick={() => this.onVisibleLocal()}>
                                            <i className="la la-info-circle" />Thông tin tài khoản
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className="btn btn-none" onClick={() => this.onVisibleLocal1()}>
                                            <i className="la la-sign-out" />Đăng xuất
                                        </Button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <SettingProfile visible={visible} onVisible={this.onVisible} />
                <Logout visible1={visible1} onVisible1={this.onVisible1} />
            </React.Fragment>
        );
    }
}
const mapStateToProps = ({ persist }) => ({
    theme_color: persist.theme_color,
    cart_product: persist.cart_product,
    depot_current: persist.depot_current,
    node_id: persist.node_id,
    userRole: persist.userRole,
});

export default withRouter(connect(mapStateToProps)(Navigation));
