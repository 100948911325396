import React from 'react';
import { tclass, Link } from '../index';

const MenuItem = ((props) => {
    const { clsChildItem, item, onClickMenu } = props;
    return (
        <React.Fragment key={clsChildItem}>
            {item.show === false ? ''
                : (
                    <Link to={item.link} key={clsChildItem} onClick={() => onClickMenu(item.link)}>
                        <div className={tclass('content_menu_item_child_item', { active: item.active })}>
                            {item.active === true ? (
                                <React.Fragment>
                                    {item.img_active !== undefined && (<img src={item.img_active} alt="icon" className="img-responsive" />)}
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        {item.img !== undefined && (<img src={item.img} alt="icon" className="img-responsive" />)}
                                    </React.Fragment>
                                )}
                            {item.title}
                        </div>
                    </Link>
                )
            }
        </React.Fragment>
    );
});

export default React.memo(MenuItem);
