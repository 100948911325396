import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { omit, isEmpty, isFunction } from 'lodash';
import { tclass } from './index';

class Button extends PureComponent {
    static defaultProps = {
        loading: false,
        className: null,
        btype: 'border',
        icon: null,
        menu: [],
    }

    state = {
        show_menu: false,
    };

    onClickMenu(e, func) {
        e.stopPropagation();
        if (isFunction(func)) func(e);
    }

    renderBtnMenu() {
        const { menu } = this.props;
        const htm = menu.map((v) => (<li onClick={(e) => this.onClickMenu(e, v.onClick)}><i className={v.icon} />{v.title}</li>));
        return htm;
    }

    showMenu(e, show) {
        e.stopPropagation();
        this.setState({ show_menu: show });
    }

    render() {
        const propss = Object.assign({}, this.props);
        const classn = propss.className;
        const { show_menu } = this.state;
        return (
            <React.Fragment>

                <button
                    {...omit(propss, 'loading', 'disabled', 'menu', 'className')}
                    className={tclass(classn, {
                        'btn-menu': !isEmpty(propss.menu),
                    })}
                    type="button"
                    disabled={propss.loading === true || propss.disabled === true}
                >
                    {propss.loading
                        && <i className={tclass('icons8-load spiner')} />
                    }
                    {(propss.icon !== null && !propss.loading) && (<i className={propss.icon} />)}
                    &nbsp;
                    {propss.children}
                    {!isEmpty(propss.menu)
                        && (
                            <React.Fragment>
                                <div
                                    className="btn-slash-root"
                                    onMouseOver={(e) => this.showMenu(e, true)}
                                    onFocus={(e) => this.showMenu(e, true)}
                                    onMouseOut={(e) => this.showMenu(e, false)}
                                    onBlur={(e) => this.showMenu(e, false)}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <div className="btn-slash">
                                        <div>
                                            <i className="la la-angle-down" />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onMouseOver={(e) => this.showMenu(e, true)}
                                    onFocus={(e) => this.showMenu(e, true)}
                                    onMouseOut={(e) => this.showMenu(e, false)}
                                    onBlur={(e) => this.showMenu(e, false)}
                                    className={tclass('btn-menu-list', {
                                        'btn-menu-list-show': show_menu,
                                    })}
                                >
                                    <ul>
                                        {this.renderBtnMenu()}
                                    </ul>
                                </div>
                            </React.Fragment>
                        )
                    }
                </button>
            </React.Fragment>
        );
    }
}

Button.propTypes = {
    loading: PropTypes.bool,
    className: PropTypes.string,
    btype: PropTypes.string,
    icon: PropTypes.string,
    menu: PropTypes.any,
};

export default Button;
