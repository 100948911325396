import React from 'react';
import DefaultWidget from './DefaultWidget';
import * as operators from '../filter_common/operators';

class NumberWidget extends DefaultWidget {
  getOperators() {
    const { operators_use } = this.props;
    const op = {};
    operators_use.forEach((v) => {
      op[v] = operators.NAME[v];
    });
    return op;
  }

  renderInputElement(value, onChange) {
    const onChangeWithNumber = (e) => {
      const { value } = e.target;
      // eslint-disable-next-line no-restricted-globals
      onChange(isNaN(value) ? NumberWidget.defaultValue : value);
    };

    return <div className="form-group"><input className="form-control" type="number" value={value} onChange={onChangeWithNumber} /></div>;
  }
}

NumberWidget.defaultOperator = operators.EQUALS;
NumberWidget.defaultValue = 0;

export default NumberWidget;
