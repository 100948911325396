import React, { Component } from 'react';
import { debounce, forEach, isArray, isEmpty, isDate, isNumber } from 'lodash';
import moment from 'moment';
import * as operators from '../filter_common/operators';
import { Select, Input } from '../index';

const ON_CHANGE_DELAY = 200;

class DefaultWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.onValueChangeDebounced = debounce(props.onValueChange, ON_CHANGE_DELAY);
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.state;
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    return (
      <div>
        {this.renderOperatorSelector()}
        {this.renderInputs()}
      </div>
    );
  }

  renderOperatorSelector() {
    const { operator, onOperatorChange } = this.props;
    return (
      <Select
        value={operator}
        onChange={(e) => onOperatorChange(e.value)}
        options={this.renderOperators()}
        placeholder="--Lựa chọn--"
        isSearchable
      />
    );
  }

  renderOperators() {
    const operators = this.getOperators();
    const options = [];
    forEach(operators, (v, k) => {
      const tmp = { value: k, label: v };
      options.push(tmp);
    });
    return options;
  }

  getOperators() {
    return {};
  }

  renderInputs() {
    const { operator } = this.props;
    switch (operator) {
      case operators.BETWEEN:
      case operators.NOT_BETWEEN:
        return this.renderDoubleInputs();
      default:
        return this.renderSingleInput();
    }
  }

  renderSingleInput() {
    const { value } = this.state;
    return this.renderInputElement(value, this.onValueChange.bind(this));
  }

  renderDoubleInputs() {
    const { value } = this.state;
    const { defaultValue } = this.props;
    let [value1, value2] = value;
    // eslint-disable-next-line prefer-destructuring
    if (value1 === null && isArray(defaultValue) && (!isEmpty(defaultValue[0]) || isNumber(defaultValue[0]))) {
      value1 = isDate(defaultValue[0]) ? moment(defaultValue[0]).format('DD/MM/YYYY HH:mm') : defaultValue[0];
    }
    // eslint-disable-next-line prefer-destructuring
    if (value2 === null && isArray(defaultValue) && (!isEmpty(defaultValue[1]) || isNumber(defaultValue[1]))) {
      value2 = isDate(defaultValue[1]) ? moment(defaultValue[1]).format('DD/MM/YYYY HH:mm') : defaultValue[1];
    }

    return (
      <div>
        {this.renderInputElement(value1, (value) => this.onValueChange([value, value2]))}
        <span style={{ marginRight: 15, marginLeft: 0, marginTop: 7, marginBottom: 7, display: 'inline-block', float: 'left' }}> đến </span>
        {this.renderInputElement(value2, (value) => this.onValueChange([value1, value]))}
      </div>
    );
  }

  renderInputElement(value, onChange) {
    return <Input className="form-control" value={value} onChange={(e) => onChange(e.target.value)} />;
  }

  onValueChange(value) {
    this.setState({ value });
    this.onValueChangeDebounced(value);
  }
}

export default DefaultWidget;
