/* eslint-disable quotes */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, omit } from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';
import strlen from 'locutus/php/strings/strlen';
import { tclass } from './index';

class Textarea extends PureComponent {
    static defaultProps = {
        autosize: { minRows: 2, maxRows: 10 },
        value: '',
        className: 'form-control',
        label: '',
    }

    constructor(props) {
        super(props);
        this.myRef = null;
        this.state = { totalCharter: strlen(props.value) };
    }

    onChangeTextArea = (e) => {
        const { onChange } = this.props;
        const { value } = e.target;
        this.setState({ totalCharter: strlen(value) });
        if (onChange) onChange(e);
    }

    render() {
        const { totalCharter } = this.state;
        // eslint-disable-next-line prefer-const
        let { autosize, className, value, label } = this.props;
        className += ' no-resize';
        return (
            <React.Fragment>
                <div className="form-group">
                    {!isEmpty(label)
                        && <label className="btn-w100"><span className="pull-left">{label}</span> <span className="pull-right">{totalCharter}</span></label>
                    }
                    <TextareaAutosize
                        minRows={autosize.minRows}
                        maxRows={autosize.maxRows}
                        value={value}
                        {...omit(this.props, ['rows', 'className', 'onChange', 'autosize'])}
                        onChange={this.onChangeTextArea}
                        className={tclass(className)}
                        // eslint-disable-next-line no-return-assign
                        inputRef={(tag) => this.myRef = tag}
                    />
                </div>
            </React.Fragment>
        );
    }
}

Textarea.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    autosize: PropTypes.object,
};

export default Textarea;
