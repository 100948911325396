import React, { Component } from 'react';
import waterfall from 'async/waterfall';
import { withRouter } from 'react-router-dom';
import { tclass, Input, Button, notification, Spin } from '../../common/library';
import { getInfoApi } from '../../../services/api/fetch';

class Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            forms: {
                password: { value: '', validate: true, msg: null },
                new_password: { value: '', validate: true, msg: null },
                cf_new_password: { value: '', validate: true, msg: null },
            },
        };
    }

    onChangeInput(type, e) {
        const { forms } = this.state;
        const { value } = e.target;
        forms[type].value = value;
        forms[type].validate = true;
        this.setState({ forms });
    }

    submitForm = () => {
        const self = this;
        const { forms } = this.state;
        waterfall([
            (callback) => {
                if (forms.password.value.length >= 8) {
                    forms.password.validate = true;
                } else {
                    forms.password.validate = false;
                    forms.password.msg = 'Vui lòng kiểm tra lại mật khẩu';
                }
                self.setState({ forms }, () => {
                    if (forms.password.validate) callback(null, 'next');
                });
            },
            (next, callback) => {
                if (forms.new_password.value.length >= 8) {
                    forms.new_password.validate = true;
                } else {
                    forms.new_password.validate = false;
                    forms.new_password.msg = 'Mật khẩu tối thiểu 8 kí tự';
                }
                self.setState({ forms }, () => {
                    if (forms.new_password.validate) callback(null, 'next');
                });
            },
            (next, callback) => {
                if (forms.cf_new_password.value === forms.new_password.value) {
                    forms.cf_new_password.validate = true;
                } else {
                    forms.cf_new_password.validate = false;
                    forms.cf_new_password.msg = 'Mật khẩu không trùng khớp';
                }
                self.setState({ forms }, () => {
                    if (forms.cf_new_password.validate) callback(null, 'next');
                });
            },
        ], () => {
            const params = {
                password: forms.password.value,
                new_password: forms.new_password.value,
                cf_new_password: forms.cf_new_password.value,
                mtype: 'profile_change_password',
            };
            self.changePassword(params);
        });
    }

    changePassword(params) {
        this.setState({ loading: true });
        const { history } = this.props;
        getInfoApi({ params }).then((data) => {
            this.setState({ loading: false });
            if (data.message) {
                notification('success', 'Thành công', 'Mật khẩu đã được cập nhật');
                history.push('/login');
            } else {
                notification('error', 'Thất bại', 'Mật khẩu cũ chưa chính xác');
            }
        });
    }

    render() {
        const { forms, loading } = this.state;
        return (
            <React.Fragment>
                <Spin spin={loading} txt="Đang cập nhật thay đổi">
                    <div className={tclass('setting_profile setting_profile_tab2')}>
                        <Input
                            onChange={(e) => this.onChangeInput('password', e)}
                            className={tclass('form-control')}
                            label="Mật khẩu cũ"
                            placeholder="Mật khẩu cũ"
                            ic_left="la la-key"
                            type="password"
                            error={forms.password.validate === false ? forms.password.msg : ''}
                            value={forms.password.value}
                            require
                        />
                        <Input
                            onChange={(e) => this.onChangeInput('new_password', e)}
                            className={tclass('form-control')}
                            label="Mật khẩu mới"
                            placeholder="Mật khẩu mới"
                            type="password"
                            ic_left="la la-key"
                            error={forms.new_password.validate === false ? forms.new_password.msg : ''}
                            value={forms.new_password.value}
                            require
                        />
                        <Input
                            onChange={(e) => this.onChangeInput('cf_new_password', e)}
                            className={tclass('form-control')}
                            label="Xác nhận mật khẩu mới"
                            placeholder="Xác nhận mật khẩu mới"
                            type="password"
                            ic_left="la la-key"
                            error={forms.cf_new_password.validate === false ? forms.cf_new_password.msg : ''}
                            value={forms.cf_new_password.value}
                            require
                        />
                        <Button onClick={() => this.submitForm()} className={tclass('btn btn-primary')}>Cập nhật</Button>
                    </div>
                </Spin>
            </React.Fragment>
        );
    }
}

export default withRouter(Password);
