import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';

export default class Tooltip extends PureComponent {
    render() {
        const { content, children } = this.props;
        return (
            <React.Fragment>
                <span data-tip={content}>{children}</span>
                <ReactTooltip effect="solid" />
            </React.Fragment>
        );
    }
}
