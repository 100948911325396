import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, omit } from 'lodash';
import { Imageload, tclass } from './index';

class Avatar extends PureComponent {

    render() {
        const propss = Object.assign({}, this.props);
        let classN = 'avatar';
        if (!isEmpty(propss.className)) {
            classN += ' ' + propss.className;
        }

        return (
            <React.Fragment>
                {!isEmpty(propss.src)
                    ? (<div {...omit(propss, 'className')} className={tclass(classN)}>
                        <Imageload src={propss.src} />
                    </div>)

                    : (<div {...omit(propss, 'className')} className={tclass(classN)}>
                        <i className={tclass('icons8-user-avatar')}></i>
                    </div>)
                }

            </React.Fragment>
        );
    }
}

Avatar.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
};

export default Avatar;


