import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, forEach, unionBy, find, isArray, filter } from 'lodash';
import moment from 'moment';
import isset from 'locutus/php/var/isset';
import FilterItem from './FilterItem';
import StringWidget from '../filter_components/StringWidget';
import NumberWidget from '../filter_components/NumberWidget';
import DateWidget from '../filter_components/DateWidget';
import BooleanWidget from '../filter_components/BooleanWidget';
import SelectWidget from '../filter_components/SelectWidget';
import { dataFilter, addFilter, clearFilters, changeFilterField, changeFilterOperator, changeFilterValue, removeFilter } from '../../../../reducers/thunk/thunk_filter';
import { Input } from '../index';

class FilterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_filter: false,
            keyword: !isEmpty(props.defaultKeyword) ? props.defaultKeyword : '',
        };
    }

    renderList() {
        const { filters } = this.props;
        if (isEmpty(filters)) return null;
        return (
            <ul className="filter-list">
                {filters.entrySeq().map(this.renderItem.bind(this))}
            </ul>
        );
    }

    changeFilterField_f(filterId, fieldName) {
        const { dispatch } = this.props;
        dispatch(changeFilterField(filterId, fieldName));
    }

    changeFilterOperator_f(filterId, operator) {
        const { dispatch } = this.props;
        dispatch(changeFilterOperator(filterId, operator));
    }

    changeFilterValue_f(filterId, value) {
        const { dispatch } = this.props;
        dispatch(changeFilterValue(filterId, value)).then(() => {
            this.submitFilterLocal();
        });
    }

    removeFilter_f(filterId) {
        const { dispatch } = this.props;
        dispatch(removeFilter(filterId)).then(() => {
            this.submitFilterLocal();
        });
    }

    renderItem([filterId, filter]) {
        const { availableFields } = this.props;
        return (
            <FilterItem
                key={filterId}
                availableFields={availableFields}
                onFieldChange={(fieldName) => this.changeFilterField_f(filterId, fieldName)}
                onOperatorChange={(operator) => this.changeFilterOperator_f(filterId, operator)}
                onValueChange={(value) => this.changeFilterValue_f(filterId, value)}
                onRemoveClick={() => this.removeFilter_f(filterId)}
                filter={filter}
            />
        );
    }

    addNewFilter = () => {
        const { dispatch } = this.props;
        dispatch(addFilter());
    }

    clearAllFilters = () => {
        this.clearAllFiltersLocal();
    }

    clearAllFiltersLocal() {
        const { dispatch } = this.props;
        dispatch(clearFilters()).then(() => {
            this.submitFilterLocal();
        });
    }

    submitFilterLocal() {
        const { keyword } = this.state;
        const { filters, onSubmit, dataSourceFilter } = this.props;
        const filters_new = [
            {
                name: 'keyword',
                operator: 'LIKE',
                value: keyword,
            },
        ];
        forEach(filters.toJS(), (v) => {
            const tmp = {};
            tmp.name = v.field.name;
            tmp.operator = v.operator;
            tmp.value = v.value;
            filters_new.push(tmp);
        });
        // const filters_new_full = [];
        forEach(dataSourceFilter, (v) => {
            //Kiểm tra xem có thằng nào có dữ liệu mặc định không.
            const item_in_filters_new = find(filters_new, (o) => o.name === v.name);
            if (isset(v.widgetOptions.defaultValue) && item_in_filters_new) {
                const { defaultValue } = v.widgetOptions;
                const tmp = {};
                tmp.name = v.name;
                // eslint-disable-next-line prefer-destructuring
                tmp.operator = item_in_filters_new.operator;
                tmp.value = (v.widgetOptions.options_multi === true || item_in_filters_new.operator === 'BETWEEN') ? defaultValue : defaultValue[0];
                //Tìm kiếm xem trong dữ liệu nó có thằng nào rồi và thằng nào chưa có
                if (isArray(tmp.value)) {
                    //Xem nó có thằng nào rồi.
                    if (isset(item_in_filters_new.value[0]) && item_in_filters_new.value[0] !== '' && item_in_filters_new.value[0] !== null && item_in_filters_new.value[0] !== undefined) {
                        // eslint-disable-next-line prefer-destructuring
                        tmp.value[0] = item_in_filters_new.value[0];
                    }
                    if (isset(item_in_filters_new.value[1]) && item_in_filters_new.value[1] !== '' && item_in_filters_new.value[1] !== null && item_in_filters_new.value[1] !== undefined) {
                        // eslint-disable-next-line prefer-destructuring
                        tmp.value[1] = item_in_filters_new.value[1];
                    }
                }
                if (v.widget === 'DateWidget') {
                    if (isArray(tmp.value)) {
                        tmp.value = tmp.value.map((v1) => {
                            let tmp_v = v1;
                            tmp_v = moment(tmp_v).format('YYYY-MM-DD HH:mm');
                            return tmp_v;
                        });
                    } else {
                        tmp.value = moment(tmp.value).format('YYYY-MM-DD HH:mm');
                    }
                }
                filters_new.push(tmp);
            }
        });

        let filters_new_full = filter(filters_new, (o) => o.value !== undefined);
        filters_new_full = filters_new_full.map((v) => {
            if (v.value !== '' && v.value !== null && v.value !== undefined) {
                return v;
            }
            return null;
        });
        filters_new_full = filter(filters_new_full, (o) => o !== null);
        if (onSubmit) onSubmit(unionBy(filters_new_full, 'name'));
    }

    submitFilter = () => {
        this.submitFilterLocal();
    }

    showFilter = () => {
        const { dataSourceFilter, dispatch } = this.props;
        const { show_filter } = this.state;
        if (!isEmpty(dataSourceFilter)) {
            const dataFilter_new = dataSourceFilter.map((v) => {
                const tmp = { ...v };
                if (tmp.widget === 'StringWidget') tmp.widget = StringWidget;
                if (tmp.widget === 'NumberWidget') tmp.widget = NumberWidget;
                if (tmp.widget === 'DateWidget') tmp.widget = DateWidget;
                if (tmp.widget === 'BooleanWidget') tmp.widget = BooleanWidget;
                if (tmp.widget === 'SelectWidget') tmp.widget = SelectWidget;
                return tmp;
            });
            dispatch(dataFilter(dataFilter_new)).then(() => {
                this.clearAllFilters();
                this.setState({ show_filter: !show_filter });
            });
        }
    }

    onChangeSearch = (e) => {
        this.setState({ keyword: e.target.value });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitFilterLocal();
        }
    }

    render() {
        const { filters, isSearch, placeholder } = this.props;
        const { show_filter, keyword } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="row filter_products_box_btn">
                        <div className="col-md-1 col-sm-2 col-xs-3 margin-bottom-15">
                            <button type="button" className="btn btn-primary btn-min-w" onClick={this.showFilter}><i className="la la-filter" /> Bộ lọc</button>
                        </div>
                        <div className="col-md-5 col-sm-3 col-xs-5" style={{ display: isSearch ? 'block' : 'none' }}>
                            <div className="form-group">
                                <div className="input-content">
                                    <Input
                                        onChange={this.onChangeSearch}
                                        onKeyPress={this.handleKeyPress}
                                        className="form-control"
                                        type="text"
                                        placeholder={placeholder}
                                        autoComplete="keyword"
                                        value={keyword}
                                    />
                                    <div className="icon_input_left">
                                        <i className="la la-group icon icon_left" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-4" style={{ display: (!filters.isEmpty() || !isSearch) ? 'none' : 'block' }}>
                            <button type="button" className="btn btn-primary" onClick={this.submitFilter}><i className="la la-search" /> Tìm kiếm</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ display: !show_filter ? 'none' : 'block' }}>
                    {this.renderList()}
                    <div className="filter_products_btn filter_products_list_btn">
                        <button type="button" className="btn btn-warning pull-left margin-right-15" onClick={this.addNewFilter}><i className="la la-plus" /> Thêm điều kiện</button>
                        <button type="button" className="btn btn-danger margin-bottom-15" onClick={this.clearAllFilters} disabled={filters.isEmpty()}><i className="la la-times" /> Xóa hết</button>
                    </div>
                    <div>
                        <button type="button" className="btn btn-primary margin-bottom-15" onClick={this.submitFilter} disabled={filters.isEmpty()}><i className="la la-search" /> Lọc và tìm kiếm</button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ state_filter }) => ({
    filters: state_filter.get('filters'),
    availableFields: state_filter.get('selectedFields'),
});

export default connect(mapStateToProps)(FilterList);
