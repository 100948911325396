import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Input, tclass } from './index';
import { removeUnicode } from '../../../helpers';

class Autocomplete extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            listComplete: props.dataSource,
            match: 0,
        };
        this.myRef = React.createRef();
        this.onClickOutside = this.onClickOutside.bind(this);
    }

    onSearch = (e) => {
        const self = this;
        if (isEmpty(e.target.value)) {
            return this.setState({ listComplete: self.props.dataSource, match: self.props.dataSource.length });
        }
        const childrenWithExtraProp = React.Children.map(self.props.dataSource, (child) => {
            const { searchVia } = self.props;
            let str = removeUnicode(child.props[searchVia]);
            str = str.toLowerCase();
            const keyword = removeUnicode(e.target.value).toLowerCase();
            if (str.search(keyword) !== -1) {
                return child;
            }
        });
        const match = childrenWithExtraProp.length;
        this.setState({ listComplete: childrenWithExtraProp, match });
        return 1;
    }

    onForcusInput = () => {
        document.addEventListener('mousedown', this.onClickOutside);
        const self = this;
        return this.setState({ listComplete: self.props.dataSource, match: self.props.dataSource.length });
    }


    onClickOutside(event) {
        if (this.myRef && !this.myRef.current.contains(event.target)) {
            this.setState({ listComplete: null, match: 0 }, () => {
                document.removeEventListener('mousedown', this.onClickOutside);
            });
        }
    }

    render() {
        const propss = Object.assign({}, this.props);
        const { listComplete, match } = this.state;

        return (
            <React.Fragment>
                <div className={tclass('autocomplete')} ref={this.myRef}>
                    <Input
                        className={tclass('form-control', {})}
                        type="text"
                        value={propss.value}
                        placeholder={propss.placeholder}
                        onChange={this.onSearch}
                        onFocus={this.onForcusInput}
                    />
                    <div className={tclass('autocomplete_content')}>
                        <div className={tclass('autocomplete_content_list', { show: match !== 0 })} ref={this.myRef}>
                            {listComplete}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Autocomplete.propTypes = {
    placeholder: PropTypes.string,
    searchVia: PropTypes.string,
    value: PropTypes.string,
    // dataSource: PropTypes.func,
};

export default Autocomplete;
