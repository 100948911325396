import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import { Radio as Radios } from 'react-icheck';
import PropTypes from 'prop-types';

class Radio extends PureComponent {
    static defaultProps = {
        name: 'aaa',
        increaseArea: '20%',
        label: 'radio',
        disabled: false,
        value: 0,
    }

    render() {
        const { name, increaseArea, label, disabled, value } = this.props;
        const propss = omit(this.props, ['name', 'increaseArea', 'label', 'value', 'disabled']);
        return (
            <Radios
                name={name}
                value={value}
                radioClass="iradio_flat-blue"
                increaseArea={increaseArea}
                label={label}
                disabled={disabled}
                {...propss}
            />
        );
    }
}
/* eslint-disable*/
Radio.propTypes = {
    increaseArea: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
};
/* eslint-enable */

export default Radio;
