import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { tclass } from './index';

class Popconfirm extends PureComponent {
    static defaultProps = {
        txtCancel: 'Hủy',
        txtOk: 'Xác nhận',
        onConfirm() { },
    }

    constructor(props) {
        super(props);
        this.Id = `popconfirm-${Math.floor((Math.random() * 999999) + 1)}`;
        this.myRef = React.createRef();
        this.onClickOutside = this.onClickOutside.bind(this);
    }

    removeEl() {
        //remove sự kiện click ra ngoài
        document.removeEventListener('mousedown', this.onClickOutside);
        const el = document.getElementById(this.Id);
        el.className = el.className.replace(tclass('zoomIn'), tclass('zoomOut'));
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById(tclass('popconfirm-box')));
            el.remove();
        }, 200);
    }

    onCancel() {
        //Xóa pop
        this.removeEl();
    }

    onOk() {
        //Xóa pop
        this.removeEl();
        if (this.props.onConfirm) this.props.onConfirm();
    }


    onClickOutside(event) {
        if (this.myRef && !this.myRef.current.contains(event.target)) {
            this.removeEl();
        }
    }

    onCClick(currentTarget, position) {
        //Lắng nghe click ra ngoài hay vào trong
        document.addEventListener('mousedown', this.onClickOutside);
        const { title, txtCancel, txtOk,className } = this.props;

        const { top, left } = position;
        const element = (<div {...omit(this.props, ['className', 'visible', 'onConfirm', 'txtCancel', 'txtOk'])} ref={this.myRef} className={tclass('popconfirm')} id={this.Id}>
            <div className={tclass('popconfirm_content_root')}>
                <div className={tclass('popconfirm_content')}>
                    <div className={tclass('popconfirm_content_msg')}>
                        <div className={tclass('popconfirm_content_msg_info')}>
                            <i className={tclass('icons8-info')} />
                        </div>
                        <div className={tclass('popconfirm_content_msg_info_txt')}>
                            {title}
                        </div>
                    </div>
                    <div className={tclass('popconfirm_content_btn')}>
                        <button className={tclass('default')} onClick={() => this.onCancel()}>{txtCancel}</button>
                        <button className={tclass('primary')} onClick={() => this.onOk()}>{txtOk}</button>
                    </div>
                </div>
            </div>
        </div>);

        ReactDOM.render(element, document.getElementById(tclass('popconfirm-box')), () => {
            const { clientWidth, clientHeight } = this.myRef.current;
            const el = document.getElementById(this.Id);
            el.style.top = `${top - clientHeight - 5}px`;
            el.style.left = `${left - (clientWidth / 2)}px`;
            el.className += ' animated zoomIn show ';
            el.className += tclass(className);
            el.className = tclass(el.className);
            
        });
    }

    render() {
        const self = this;
        const propss = Object.assign({}, this.props);
        const childrenWithExtraProp = React.Children.map(propss.children, (child) => {
            const elment = React.cloneElement(child, {
                onClick: (e) => self.onCClick(e.currentTarget, e.currentTarget.getBoundingClientRect()),
            });
            return elment;
        });

        return (
            <React.Fragment>
                {childrenWithExtraProp}
            </React.Fragment >
        );
    }
}

Popconfirm.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    txtCancel: PropTypes.string,
    txtOk: PropTypes.string,
    onConfirm: PropTypes.func,
};

export default Popconfirm;

