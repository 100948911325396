
import localforage from 'localforage';
// import connectFirebase from '../firebase/connectFirebase';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { user_api, getInfoApi } from '../../services/api/fetch';

moment().utcOffset(7);
export default class AuthService {
    // constructor() {
    //     // this.firebase = new connectFirebase();
    // }

    login(params_source) {
        const params = { ...params_source };
        params.mtype = 'login';
        return user_api(params).then((data) => {
            if (data.token !== undefined) {
                const exp = moment().add(1, 'hours').toString();
                return localforage.setItem('token_exp', exp).then(() => Promise.resolve(data));
            }
            return Promise.resolve(data);
        });
    }

    async getInfo(params) {
        return this.getToken().then(() => getInfoApi({ params }).then(({ user_info }) => {
            return user_info;
        }));
    }

    async loggedIn() {
        //Check xem còn thời gian không đã.
        return localforage.getItem('token_exp').then((exp) => {
            if (isEmpty(exp) || moment().isSameOrAfter(exp)) {
                return false;
            }
            return this.getToken().then((token) => !!token);
        });
    }

    // async setProfile(profile) {
    //     // Saves profile data to localStorage
    //     return localforage.setItem('profile', profile).then(() => {
    //         return true;
    //     });
    // }

    // async getProfile() {
    //     return localforage.getItem('profile').then((profile) => {
    //         return profile;
    //     });
    // }

    setToken(idToken, cb) {
        localforage.setItem('id_token', idToken, () => {
            if (cb) cb(null, idToken);
        });
    }

    async getToken() {
        // Retrieves the user token from localStorage
        return localforage.getItem('id_token').then((id_token) => id_token);
    }

    logout(cb) {
        // const regex1 = new RegExp(/_pouch_nxt_([a-z]+)_(\d+)_(\d+)$/gi);
        // const regex2 = new RegExp(/_pouch_nxt_([a-z]+)_undefined$/gi);
        const regex3 = new RegExp(/persist:(.*)$/ig);
        // const arrs_keys = [];
        /*eslint-disable */
        for (const a in localStorage) {
            // if (regex1.test(a) || regex2.test(a)) {
            //     arrs_keys.push(a);
            // }
            if (regex3.test(a)) {
                localStorage.removeItem(a);
            }
        }
        if (cb) cb(null, true);
        /*eslint-enable */
        //let i = 0;
        // whilst(
        //     () => i < arrs_keys.length,
        //     (callback) => {
        //         i++;
        //         const item = arrs_keys[i - 1];
        //         const db = new PouchDB(item);
        //         db.destroy(() => {
        //             callback(null, i);
        //         });
        //     },
        //     () => {
        //         localStorage.clear();
        //         if (cb) cb(null, true);
        //     },
        // );

        // for (let a in localStorage) {
        //     arrs_keys.push(a);
        //     // console.log('===================================');
        //     // console.log(a);
        //     // console.log('===================================');
        //     // localStorage.clear();
        //     // if (regex1.test(a) || regex2.test(a)) {
        //     //     const db = new PouchDB(a);
        //     //     pdb.destroy(function (err, response) {
        //     //         if (err) {
        //     //             console.log(err);
        //     //         } else {
        //     //             // success
        //     //             console.log(response);
        //     //         }
        //     //     });
        //     // }
        //     // console.log(a, ' = ', localStorage[a]);
        // }
        // localStorage.get
        // localforage.keys().then(function (keys) {
        //     // An array of all the key names.
        //     console.log(keys);
        // }).catch(function (err) {
        //     // This code runs if there were any errors
        //     console.log(err);
        // });
        // console.log(localforage.keys)
        // return this.firebase.cFireBase().then(() => {
        //     return this.firebase.logoutFireBase().then((done) => {
        //         if (done === true) {
        // localforage.clear().then(() => {
        //     if (cb) cb(null, true);
        //     // Run this code once the database has been entirely deleted.
        //     // console.log('Database is now empty.');
        // }).catch((err) => {
        //     // This code runs if there were any errors
        //     console.log(err);
        // });
        //         } else {
        //             return false;
        //         }
        //     });
        // });
    }
}
