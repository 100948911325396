import React from 'react';
import DefaultWidget from './DefaultWidget';
import MultiSelect from './MultiSelect';
import * as operators from '../filter_common/operators';

class SelectWidget extends DefaultWidget {
  getOperators() {
    const { operators_use } = this.props;
    const op = {};
    operators_use.forEach((v) => {
      op[v] = operators.NAME[v];
    });
    return op;
  }

  renderInputs() {
    const { value, options, onValueChange, options_multi, defaultValue } = this.props;
    const defaultVl = defaultValue !== undefined ? defaultValue : null;
    let multi = false;
    if (options_multi === true) multi = true;

    return <MultiSelect value={value} options={options} defaultValue={defaultVl} options_multi={multi} onChange={onValueChange} />;
  }
}

SelectWidget.defaultOperator = operators.EQUALS;

export default SelectWidget;
