import * as Types from '../actions';

const initialState = {
    version: 1.0,
    profile: {},
    token: '',
    theme_color: 'theme_default',
    form_login: {
        node_name: '',
        username: '',
    },
    depots: [],
    depot_current: 0,
    node_id: null,
    node_info: null,
    field_show_product: {
        sku: true,
        name: true,
        category: true,
        price: true,
        price_market: true,
        quantity: true,
        inventory_min: true,
        inventory_max: true,
        status: true,
    },
    pos_open_print: true,

    /*------- List bán hàng ------------------*/
    list_sell: [],
    /*------- List nhập kho ------------------*/
    list_warehousing: [],
    list_warehousing_save: [],
    field_show_warehousing: {
        sku: true,
        time: true,
        time_create: true,
        supplier: true,
        depot: true,
        user: true,
        total_number: true,
        total_product: true,
        total_price: true,
        pay_price: true,
        pay_need_price: true,
        note: true,
        status: true,
    },
    /*------- List chuyển kho ------------------*/
    list_transfer: [],
    list_transfer_save: [],
    field_show_transfer: {
        invoice: true,
        from_depot: true,
        to_depot: true,
        user: true,
        time_created: true,
        time_at: true,
        total: true,
        amount: true,
        total_receive: true,
        amount_receive: true,
        total_product: true,
        note: true,
        status: true,
    },

    userPermission: [],
    userRole: [],


    /*--------- Menu phiếu thu chi  ------------- */
    field_show_receipts: {
        invoice: true,
        time: true,
        time_created: true,
        personnel: true,
        depot: true,
        category: true,
        payer: true,
        amount: true,
        note: true,
        status: true,
    },
    cart_product: [],

    bills: [],
    /*------- List kiểm kho ------------------*/
    list_stocktakes: [],
    list_stocktakes_save: [],
    field_show_stocktakes: {
        invoice: true,
        time_create: true,
        update_at: true,
        total_unmatch: true,
        total_price_unmatch: true,
        increase_unmatch: true,
        price_increase_unmatch: true,
        decrease_unmatch: true,
        price_decrease_unmatch: true,
        note: true,
        status: true,
    },

    /*------- List xuất huỷ ------------------*/
    list_damage: [],
    list_damage_save: [],
    field_show_damage: {
        invoice: true,
        created_at: true,
        created_time: true,
        price: true,
        depot: true,
        user_create: true,
        user_id: true,
        note: true,
        status: true,
    },

    /*------- List trả hàng nhập ------------------*/
    list_purchase_return: [],
    list_purchase_return_save: [],
    field_show_purchase_return: {
        sku: true,
        time: true,
        time_create: true,
        supplier: true,
        depot: true,
        user: true,
        total_number: true,
        total_product: true,
        total_price: true,
        pay_price: true,
        pay_need_price: true,
        note: true,
        status: true,
    },
    /*------- ZOOM PAGE ------------------*/
    page_zoom: 100,
    /*------- OPEN-BOX-PRODUCT-SALE ------------------*/
    open_box_product_sale: true,

    /*------- Affiliate ------------------*/
    affiliate_code: null,
};

const routerReducersStatic = (state = initialState, { type, payload } = {}) => {
    let states = Object.assign({}, state);
    switch (type) {
        case 'R_TOTAL_ONLINE':
            states = { ...state, version: payload }; break;
        // states = { ...state, version: payload }; break;
        case Types.R_PROFILE:
            states = { ...state, profile: payload }; break;

        case Types.R_TOKEN:
            states = { ...state, token: payload }; break;

        case Types.R_FORM_LOGIN:
            states = { ...state, form_login: payload }; break;

        case Types.R_NODE_ID:
            states = { ...state, node_id: payload }; break;

        case Types.R_NODE_INFO:
            states = { ...state, node_info: payload }; break;

        case Types.R_DEPOT_LIST:
            states = { ...state, depots: payload }; break;

         case Types.R_EMAIL_EDITER_LIST:
            states = { ...state, editerMail: payload }; break;

        case Types.R_DEPOT_CURRENT:
            states = { ...state, depot_current: payload }; break;

        case Types.R_FIELD_SHOW_PRODUCT:
            states = { ...state, field_show_product: payload }; break;

        case Types.R_SELL:
            states = { ...state, list_sell: payload }; break;

        case Types.R_WAREHOUSING:
            states = { ...state, list_warehousing: payload }; break;

        case Types.R_EDIT_WAREHOUSING:
            states = { ...state, list_warehousing_save: payload }; break;

        case Types.R_TRANSFER:
            states = { ...state, list_transfer: payload }; break;

        case Types.R_EDIT_TRANSFER:
            states = { ...state, list_transfer_save: payload }; break;

        case Types.R_FIELD_SHOW_WAREHOUSING:
            states = { ...state, field_show_warehousing: payload }; break;
        case Types.R_FIELD_SHOW_TRANSFER:
            states = { ...state, field_show_transfer: payload }; break;

        case Types.R_PERMISSION:
            states = { ...state, userPermission: payload }; break;
        case Types.R_ROLE:
            states = { ...state, userRole: payload }; break;

        case Types.R_THEMES_DEFAULT:
            states = { ...state, theme_color: payload }; break;

        case Types.R_CART:
            states = { ...state, cart_product: payload }; break;

        case Types.R_FIELD_SHOW_RECEIPTS:
            states = { ...state, field_show_receipts: payload }; break;

        case Types.R_STOCK_TAKES:
            states = { ...state, list_stocktakes: payload }; break;

        case Types.R_EDIT_STOCKTAKES:
            states = { ...state, list_stocktakes_save: payload }; break;

        case Types.R_FIELD_SHOW_STOCKTAKES:
            states = { ...state, field_show_stocktakes: payload }; break;
        case Types.R_DAMAGE:
            states = { ...state, list_damage: payload }; break;
        case Types.R_EDIT_DAMAGE:
            states = { ...state, list_damage_save: payload }; break;
        case Types.R_FIELD_SHOW_DAMAGE:
            states = { ...state, field_show_damage: payload }; break;
        case Types.R_PURCHASE_RETURN:
            states = { ...state, list_purchase_return: payload }; break;
        case Types.R_EDIT_PURCHASE_RETURN:
            states = { ...state, list_purchase_return_save: payload }; break;
        case Types.R_FIELD_SHOW_PURCHASE_RETURN:
            states = { ...state, field_show_purchase_return: payload }; break;
        case Types.R_BILLS:
            states = { ...state, bills: payload }; break;
        case Types.R_POS_OPEN_PRINT:
            states = { ...state, pos_open_print: payload }; break;
        case Types.R_PAGE_ZOOM:
            states = { ...state, page_zoom: payload }; break;
        case Types.R_OPEN_BOX_PRODUCT_SALE:
            states = { ...state, open_box_product_sale: payload }; break;
        case Types.R_AFFILLIATE_CODE:
            states = { ...state, affiliate_code: payload }; break;
        default:
            states = state;
            break;
    }
    return states;
};

export default routerReducersStatic;
