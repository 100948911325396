import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { Imageload } from '../../common/library';

export default class UploadImage extends PureComponent {
    onDrops(file) {
        const { onUpload } = this.props;
        onUpload(file[0]);
    }

    render() {
        const { profile } = this.props;
        return (
            <React.Fragment>
                <div className="edit_user_upload_image">
                    <Dropzone
                        onDrop={(file) => this.onDrops(file)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps()}
                                className="edit_user_upload_image_box"
                            >
                                <input {...getInputProps()} />
                                <Imageload src={profile.photo} />
                            </div>
                        )}
                    </Dropzone>
                </div>
            </React.Fragment>
        );
    }
}
