import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { List } from 'immutable';
import MobileDetect from 'mobile-detect';
import AuthService from './AuthService';
// import connectFirebase from '../firebase/connectFirebase';
import { R_TOKEN, R_PROFILE, R_PAGE_ZOOM } from '../../reducers/actions/index';
import { tclass, notification, Modal } from '../../components/common/library';
import { user_api } from '../../services/api/fetch';

export default function withAuth(AuthComponent) {
    const Auth = new AuthService();
    // const Firebase = new connectFirebase();
    const nxt = class Authenticated extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLoading: true,
                visible_size: false,
                visible_access_denied: false,
            };
            this.interval = null;
            this.widthGreat = 1280;
            this.listPermission = null;
        }


        componentWillMount() {
            const self = this;
            const { token, page_zoom } = this.props;
            this.pageZoom(page_zoom);
            //Kiểm tra xem token có tồn tại trên server hay không
            user_api({ mtype: 'validate_token', token }).then((data) => {
                if (!isEmpty(data) && data.errors !== undefined) {
                    notification('error', 'Đăng nhập lại', 'Vui lòng đăng nhập trước khi sử dụng.');
                    self.props.history.push('/login');
                }
            });
        }

        componentDidMount() {
            const self = this;
            const { token, dispatch, userPermission, theme_color, page_zoom } = this.props;
            this.listPermission = List(userPermission);
            const p = this.parseJwt(token);
            const deviceApi = new MobileDetect(window.navigator.userAgent);
            if (this.getWidthScreen() < this.widthGreat && page_zoom === 100 && isEmpty(deviceApi.mobile())) {
                this.setState({ visible_size: true });
            } else if (this.getWidthScreen() >= this.widthGreat && page_zoom !== 100) {
                dispatch({ type: R_PAGE_ZOOM, payload: 100 });
                this.pageZoom(100);
            }
            if (p !== null) {
                //Kiểm tra thời gian còn không
                const exp = moment().isSameOrAfter(moment.unix(p.exp));
                //const exp = false;
                if (exp) {
                    self.resetLogin();
                    notification('warning', 'Đăng nhập lại', 'Phiên đăng nhập đã hết hạn. </br> Vui lòng đăng nhập lại.');
                    self.props.history.push('/login');
                } else {
                    self.setState({ isLoading: false });
                }
            } else {
                notification('error', 'Đăng nhập lại', 'Vui lòng đăng nhập trước khi sử dụng.');
                self.props.history.push('/login');
            }
            if (window.document) window.document.body.className = theme_color;
        }

        // componentWillReceiveProps(nextProps) {
        //     this.checkRoles(nextProps.location.pathname);
        // }

        /*-------------RESIZE PAGE-------------- */
        submitResize = () => {
            const { dispatch } = this.props;

            const w = this.getWidthScreen();
            const wResize = Math.ceil((this.widthGreat - w) / w * 100);
            const number = 100 - wResize;
            dispatch({ type: R_PAGE_ZOOM, payload: number });
            this.pageZoom(number);
            this.closeResize();
        }

        pageZoom(size = 100) {
            const Page = document.getElementById('root_body');
            Page.style.zoom = `${size}%`;
        }

        closeResize() {
            this.setState({ visible_size: false });
        }

        getWidthScreen() {
            return window.innerWidth;
        }
        /*-------------RESIZE PAGE-------------- */

        checkRoles(pathname = null) {
            // const { history, userPermission, userRole } = this.props;
            //this.listPermission = List(userPermission);
            // const router_allow_all = [
            //     '/login', '/logout', '/access-denied', '/help,',
            // ];

            /*------------------Tìm ra những link được phép truy cập--------------------------*/
            return;
            //Nếu thuộc 1 trong các route ko chặn thì thoải mái truy cập
            // if (find(router_allow_all, (o) => o === pathname)) return;

            // //Role_id = 1 tức là quyền cao nhất
            // //Nếu chứa nhiều quyền và có tồn tại quyền 1 thì mọi thứ ok

            // if (find(userRole, (o) => o.role_id === 1)) {
            //     return;
            // }

            // //Nếu chỉ có 1 quyền 2

            // if (userRole.length === 1 && userRole[0].role_id === 2) {
            //     if (pathname !== '/point-of-sale') history.push('/point-of-sale');
            //     return;
            // }

            // //Nếu có quyền bán hàng và đang muốn truy cập vào đó thì ok
            // if (find(userRole, (o) => o.role_id === 2) && pathname === '/point-of-sale') {
            //     return;
            // }
            // //Nếu có từ 2 quyền trở lên thì
            // if (pathname === '/' || pathname === '/dashboard' || pathname === '/bpos') {
            //     history.push(userPermission[0].url_frontend);
            // } else {
            //     const is_allows = find(userPermission, (o) => o.url_frontend === pathname);
            //     if (is_allows === undefined) {
            //         notification('error', 'Giới hạn quyền', 'Bạn đang truy cập vào vùng giới hạn quyền.');
            //         history.goBack();
            //     }
            // }
        }

        parseJwt(token) {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        }

        resetLogin() {
            const { dispatch } = this.props;
            dispatch({ type: R_TOKEN, payload: '' });
            dispatch({ type: R_PROFILE, payload: {} });
        }


        refesh_token(token) {
            const { dispatch } = this.props;
            Auth.setToken(token, (e, tk) => {
                dispatch({ type: R_TOKEN, payload: tk });
            });
        }

        listenWebsiteOnline = () => {

        }

        render() {
            const { isLoading, visible_size, visible_access_denied } = this.state;
            return (
                <div>
                    {isLoading ? (
                        <div className={tclass('loader')}>
                            <div className={tclass('sk-fading-circle')}>
                                <div className={tclass('sk-circle1 sk-circle')} />
                                <div className={tclass('sk-circle2 sk-circle')} />
                                <div className={tclass('sk-circle3 sk-circle')} />
                                <div className={tclass('sk-circle4 sk-circle')} />
                                <div className={tclass('sk-circle5 sk-circle')} />
                                <div className={tclass('sk-circle6 sk-circle')} />
                                <div className={tclass('sk-circle7 sk-circle')} />
                                <div className={tclass('sk-circle8 sk-circle')} />
                                <div className={tclass('sk-circle9 sk-circle')} />
                                <div className={tclass('sk-circle10 sk-circle')} />
                                <div className={tclass('sk-circle11 sk-circle')} />
                                <div className={tclass('sk-circle12 sk-circle')} />
                            </div>
                            <div className={tclass('txt')}>Đang kết nối...</div>
                        </div>
                    ) : (
                            <React.Fragment>
                                {/* <AuthComponent {...this.props} firebase={Firebase} /> */}
                                <AuthComponent {...this.props} />
                                <Modal
                                    title="Kích thước màn hình"
                                    visible={visible_size}
                                    onOk={this.submitResize}
                                    onCancel={() => this.closeResize()}
                                    txtCancel="Đóng"
                                    txtOk="Cập nhật kích thước"
                                    size="md"
                                >
                                    <div>
                                        <p>Kích thước màn hình của bạn không phù hợp.</p>
                                        <p>Bạn cần resize để phần mềm có thể hiển thị tốt nhất</p>
                                    </div>
                                </Modal>
                                <Modal
                                    title="Access denied"
                                    visible={visible_access_denied}
                                    size="md"
                                >
                                    <div>
                                        <p>Không có quyền truy cập.</p>
                                        <p>Không có quyền truy cập</p>
                                    </div>
                                </Modal>
                            </React.Fragment>
                        )}
                </div>
            );
        }
    };
    const mapStateToProps = ({ state, persist }) => ({
        login: state.login,
        token: persist.token,
        profile: persist.profile,
        node_id: persist.node_id,
        depot_current: persist.depot_current,
        theme_color: persist.theme_color,
        userRole: persist.userRole,
        page_zoom: persist.page_zoom,
        userPermission: persist.userPermission,
    });
    return withRouter(connect(mapStateToProps)(nxt));
}
