import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { tclass } from './index';

class Tag extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            hidden: false
        };
    }

    onRemove() {
        const self = this;
        self.setState({ show: false }, () => {
            setTimeout(() => {
                self.setState({ hidden: true });
            }, 300);
            //Thực hiện 1 cái gì đó
            if (self.props.afterClose) self.props.afterClose();
        });
    }

    render() {
        const { show, hidden } = this.state;
        const propss = Object.assign({}, this.props);
        const clsName = tclass('tag animated ' + propss.color);
        return (
            <React.Fragment>
                <div className={tclass(clsName, {
                    'bounceIn': show,
                    'bounceOut': !show,
                    'hidden': hidden,
                })}>
                    <span>{propss.children}</span>
                    <i className={tclass('icons8-close-button')} onClick={() => this.onRemove()}></i>
                </div>
            </React.Fragment >
        );
    }
}

Tag.propTypes = {
    color: PropTypes.string,
};

export default Tag;


