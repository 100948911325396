import React from 'react';
import Loadable from 'react-loadable';
import Navigation from '../container/components/common/navigation/navigation';
import withAuth from '../container/core/auth/withAuth';
import Loading from '../router/Loading';


const Dashboard = Loadable({
    loader: () => import('../page/bpos/aff/aff'),
    loading: Loading,
});

const Aff = Loadable({
    loader: () => import('../page/bpos/aff/aff'),
    loading: Loading,
});

const Fcm = Loadable({
    loader: () => import('../page/bpos/fcm/fcm'),
    loading: Loading,
});

const Help = Loadable({
    loader: () => import('../page/help/help'),
    loading: Loading,
});
const Policies = Loadable({
    loader: () => import('../page/policies/policies'),
    loading: Loading,
});
const Report = Loadable({
    loader: () => import('../page/report/report'),
    loading: Loading,
});

const Body = (props) => {
    let View = (<Dashboard />);
    // const not_navigation = false;
    const { location } = props;
    const { pathname } = location;
    if (pathname === '/') {
        window.location = `/aff/Dashboard`;
    }
    const rg_st_bpos_aff = /\/aff\/([a-zA-Z0-9-_]+)$/g;
    const rg_st_bpos_fcm = /\/fcm\/([a-zA-Z0-9-_]+)$/g;
    const rg_st_aff_help = /\/help\/([a-zA-Z0-9-_]+)$/g;
    const rg_st_aff_policies = /\/policies\/([a-zA-Z0-9-_]+)$/g;
    const rg_st_aff_report = /\/report\/([a-zA-Z0-9-_]+)$/g;
    if (rg_st_bpos_aff.test(pathname)) {
        View = (<Aff {...props} />);
    }
    if (rg_st_aff_help.test(pathname)) {
        View = (<Help {...props} />);
    }
    if (rg_st_aff_policies.test(pathname)) {
        View = (<Policies {...props} />);
    }
    if (rg_st_aff_report.test(pathname)) {
        View = (<Report {...props} />);
    }
    if (rg_st_bpos_fcm.test(pathname)) {
        View = (<Fcm {...props} />);
    }
    return (
        <React.Fragment>
            <Navigation {...props} />
            {/* {!not_navigation
                && <Navigation {...props} />
            } */}
            {View}
        </React.Fragment>
    );
};

export default withAuth(Body);
