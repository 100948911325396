import { replace } from 'lodash';
import { intval } from 'locutus/php/var';
import currencyFormatter from 'currency-formatter';
/* eslint-disable*/

export function FormatPrice(number, currency = null, toMilion = false) {
    if (currency !== null) {
        return currencyFormatter.format(number, {
            symbol: currency,
            decimal: ',',
            thousand: '.',
            precision: 0,
            format: '%v%s' // %s is the symbol and %v is the value
        });
    }
    if (toMilion) {
        return toMilions(number);
    }
    return format_curency(number);
}

function toMilions(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9
        ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? Math.abs(Number(labelValue)) / 1.0e+6 + "tr"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? Math.abs(Number(labelValue)) / 1.0e+3 + "k"
                : Math.abs(Number(labelValue));

}

function format_number(string) {
    return intval(replace(string, /\./g, ''));
}
function format_curency(string) {
    let num = format_number(string);
    if (num > 0) num = numberFormat(num, 0, '.', '.');
    return num;
}
function numberFormat(numbers, decimals, dec_point, thousands_sep) {
    let number = numbers;
    number = (`${number}`).replace(/[^0-9]/g, '');
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep;
    const dec = (typeof dec_point === 'undefined') ? '.' : dec_point;
    let s = '';
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function toFixedFix(n, prec) {
    let k = Math.pow(10, prec);
    return `${Math.round(n * k) / k}`;
}

/* eslint-enable*/
