import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { unflatten } from '../../../helpers/Array';
import Button from './Button';
import Input from './Input';
import Textarea from './Textarea';
import Tooltip from './Tooltip';
import Spin from './Spin';
import Avatar from './Avatar';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Imageload from './Imageload';
import Loadpage from './Loadpage';
import Tag from './Tag';
import Modal from './Modal';
import Popconfirm from './Popconfirm';
import Autocomplete from './Autocomplete';
import Autocomplete1 from './Autocomplete1';
import Content from './Content';
import notification from './Notification';
import Empty from './Empty';
import Switch from './Switch';
import ConverByte from './ConverByte';
import Select from './Select';
import Tabs from './Tabs';
import Ckeditor from './Ckeditor';
import OutsideAlerter from './OutsideAlerter';
import RadioGroup from './RadioGroup';
import { tclass } from './tclass';
import { FormatPrice } from './FormatPrice';
import FormFilter from './FormFilter';
import Delayed from './Delayed';
import FilterForm from './FilterForm';

// import tcss from '../../../../static/styles/css/main.scss';

export {
    Button,
    Input,
    Tooltip,
    Spin,
    notification,
    Avatar,
    Checkbox,
    Imageload,
    Textarea,
    Tag,
    Modal,
    Popconfirm,
    Autocomplete,
    Autocomplete1,
    tclass,
    Link,
    Route,
    Router,
    Content,
    Radio,
    Empty,
    Switch,
    ConverByte,
    Select,
    Tabs,
    FormatPrice,
    Ckeditor,
    Loadpage,
    OutsideAlerter,
    RadioGroup,
    unflatten,
    FormFilter,
    Delayed,
    FilterForm,
};
