import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Selects from 'react-select';
import { isArray, find } from 'lodash';
import chroma from 'chroma-js';

class Select extends Component {
    static defaultProps = {
        options: [],
        onChange: () => { },
        value: 0,
        placeholder: 'Lựa chọn',
        className: 'select_custom',
        isDisabled: false,
        isMulti: false,
        isSearchable: false,
        require: false,
        isClearable: false,
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        if (onChange) onChange(e);
    }

    render() {
        const { options, value } = this.props;
        const colorDefault = '#868686';
        // const zindex = '10'
        let val = null;
        if (isArray(value)) {
            val = [];
            value.forEach((v) => {
                const tmp = find(options, (o) => o.value === v);
                val.push(tmp);
            });
        } else {
            let val1 = null;
            val1 = find(options, (o) => o.value === value);
            if (val1 === undefined) {
                val = null;
            } else {
                val = val1;
            }
        }
        /* eslint-disable */
        const colourStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = chroma(colorDefault);
                return {
                    ...styles,
                    backgroundColor: isDisabled
                        ? null
                        : isSelected ? colorDefault : isFocused ? color.alpha(0.1).css() : null,
                    color: isDisabled
                        ? '#ccc'
                        : isSelected
                            ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
                            : colorDefault,
                    cursor: isDisabled ? 'not-allowed' : 'default',
                    ':active': {
                        backgroundColor: color.alpha(0.2).css(),
                    },
                };
            },
        };
        /* eslint-enabled */

        return (
            <React.Fragment>
                <div className="form-group">
                    {this.props.label &&
                        <label>{this.props.label} {this.props.require && <span className="color_require">*</span>}</label>
                    }
                    <Selects
                        styles={colourStyles}
                        value={val}
                        onChange={this.handleChange}
                        options={options}
                        placeholder={this.props.placeholder}
                        className={this.props.className}
                        isDisabled={this.props.isDisabled}
                        isMulti={this.props.isMulti}
                        isSearchable={this.props.isSearchable}
                        isClearable={this.props.isClearable}
                        closeMenuOnSelect={this.props.closeMenuOnSelect}
                    />
                </div>
            </React.Fragment>
        );
    }
}

/* eslint-disable*/
Select.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    label: PropTypes.string,
    isClearable: PropTypes.bool,
    require: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
};
/* eslint-enable */

export default Select;
