import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FilterList from './filter/FilterList';

class FilterForm extends PureComponent {
    static defaultProps = {
        isSearch: true,
        placeholder: 'Nhập từ khóa tìm kiếm',
    }

    onSubmit = (data) => {
        const { onSubmit } = this.props;
        if (onSubmit) onSubmit(data);
    }

    render() {
        const { dataFilter, isSearch, placeholder, defaultKeyword } = this.props;
        return (
            <div className="filter_products_box">
                <FilterList defaultKeyword={defaultKeyword} placeholder={placeholder} isSearch={isSearch} dataSourceFilter={dataFilter} onSubmit={this.onSubmit} />
            </div>
        );
    }
}

FilterForm.propTypes = {
    isSearch: PropTypes.bool,
    placeholder: PropTypes.string,
};
export default FilterForm;
