import React from 'react';
import no_data from '../../../../style/image/cart_empty_icon.png';

const Empty = React.memo(() => (
    <React.Fragment>
        <div className="bpos_no_data text-center">
            <img src={no_data} alt="No data" className="img-responsive" />
            <p><strong>Chưa có dữ liệu hiển thị .</strong></p>
            <p>Hãy liên hệ với chúng tôi qua 1900.2008 nếu bạn cho rằng đây là một lỗi.</p>
        </div>
    </React.Fragment>
));
export default Empty;
