import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/vi';
import 'icheck/skins/all.css'; // or single skin css
import './style/main.scss';
import '../node_modules/react-datepicker/dist/react-datepicker.css';
import 'react-sweet-progress/lib/style.css';
import 'react-virtualized/styles.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-virtualized-select/styles.css';
// moment.locale('vi');
/**
 * Redux chung
 */
import { store, persistor } from './container/store';
/**
 * Router
 */
import TRouter from './router/router';

// console.log(mobileDetect);
ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={<div>Đang tải dữ liệu</div>}
            // onBeforeLift={(a, b) => console.log('LOAD FIRST')}
            persistor={persistor}
        >
            <TRouter />
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
const config_sw = {
    // onSuccess: () => { console.log('SW SUCCESS'); },
    // onUpdate: () => { console.log('SW UPDATE'); },
};
serviceWorker.register(config_sw);
