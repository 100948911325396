import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { tclass } from './index';

class Switch extends PureComponent {
    static defaultProps = {
        on: false,
        txtOff: 'Tắt',
        txtOn: 'Bật',
        className: '',
        size: 48,
        disabled: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            on: this.props.on,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.on !== this.state.on) this.setState({ on: nextProps.on });
    }

    onChangeSw() {
        const { on } = this.state;
        this.setState({ on: !on });
        if (this.props.onClick) this.props.onClick(!on);
    }

    render() {
        const { on } = this.state;
        return (

            <React.Fragment>
                <div className={tclass('setting_on_off', { disabled: this.props.disabled })}>
                    <div
                        className={tclass('setting_on_off_ctrol', {
                            active: on,
                        })}
                        style={{ width: this.props.size }}
                        onClick={() => this.onChangeSw()}
                    >
                        <span className={tclass('setting_on_off_status', 'on')}>{this.props.txtOn}</span>
                        <span className={tclass('setting_on_off_status', 'off')}>{this.props.txtOff}</span>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
/* eslint-disable react/no-unused-prop-types,react/require-default-props*/
Switch.propTypes = {
    className: PropTypes.string,
    txtOff: PropTypes.string,
    txtOn: PropTypes.string,
    on: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.number,
    disabled: PropTypes.bool,
};
/* eslint-enable */

export default Switch;
