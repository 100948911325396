import DefaultWidget from './DefaultWidget';
import * as operators from '../filter_common/operators';

class StringWidget extends DefaultWidget {
  getOperators() {
    const { operators_use } = this.props;
    const op = {};
    operators_use.forEach((v) => {
      op[v] = operators.NAME[v];
    });
    return op;
  }
}

StringWidget.defaultOperator = operators.CONTAINS;
StringWidget.defaultValue = '';

export default StringWidget;
