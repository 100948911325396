import React, { Component } from 'react';
import { isObject } from 'lodash';
import { Select } from '../index';

export default class MultiSelect extends Component {
  renderOptions() {
    const { options } = this.props;
    // console.log(options);
    const options_new = [];
    options.forEach(({ value, key }) => {
      const tmp = { value: key, label: value };
      options_new.push(tmp);
    });
    return options_new;
  }

  onChange = (data) => {
    const { onChange, options_multi } = this.props;
    if (!isObject(data) || options_multi !== true) {
      onChange(data.value);
    } else {
      onChange(data.map((o) => o.value));
    }
  }

  render() {
    // eslint-disable-next-line prefer-const
    let { value } = this.props;
    const { options_multi, defaultValue } = this.props;
    if (value === undefined && defaultValue !== undefined) value = defaultValue;
    return (
      <div className="multi-select">
        <Select
          value={value}
          onChange={this.onChange}
          options={this.renderOptions()}
          placeholder="--Lựa chọn--"
          // isSearchable
          // isClearable
          isMulti={options_multi}
        />
      </div>
    );
  }
}
