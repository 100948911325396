import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Spin, Tabs } from '../../common/library';
import General from './general';
import Password from './password';
import { R_PROFILE } from '../../../reducers/actions';
class SettingProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            active_tab: 'co_ban',
        };
    }

    onVisibleLocal() {

        if (this.props.onVisible) this.props.onVisible();
        const { profile, dispatch } = this.props;
        const profile_clone = Object.assign({}, profile);
        profile_clone.photo += '?v=' + new Date();
        dispatch({ type: R_PROFILE, payload: profile_clone });
    }

    renderTabs(form_profile) {
        const tabs = [
            {
                title: 'Cơ bản',
                key: 'co_ban',
                component: (<General />),
            },
            {
                title: 'Đổi mật khẩu',
                key: 'change_password',
                component: (<Password />),
            },
        ];
        return tabs;
    }

    changeTab = (tab) => {
        this.setState({ active_tab: tab });
    }

    render() {
        const { loading, active_tab } = this.state;
        const { visible } = this.props;
        return (
            <React.Fragment>
                <Modal
                    title="Cập nhật thông tin"
                    visible={visible}
                    onCancel={() => this.onVisibleLocal()}
                    txtCancel="Đóng"
                    size="md"
                >
                    <Spin spin={loading}>
                        <Tabs source={this.renderTabs()} active={active_tab} onClick={this.changeTab} />
                    </Spin>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ persist }) => ({
    profile: persist.profile,
});
export default connect(mapStateToProps)(SettingProfile);
