import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

function getOffset(el) {
    // eslint-disable-next-line no-underscore-dangle
    let _x = 0;
    // eslint-disable-next-line no-underscore-dangle
    let _y = 0;
    // eslint-disable-next-line no-restricted-globals
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        // eslint-disable-next-line no-param-reassign
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

function elementInViewport(el) {
    // const rect = el.getBoundingClientRect();
    const rect = getOffset(el);
    return (
        rect.top >= 0
        && rect.left >= 0
        && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
}


class Imageload extends Component {
    static defaultProps = {
        placeHolder: '/image/no-image.svg',
        width: '100%',
        height: '100%',
        alt: '',
        effect: 'opacity',
    }

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.imgElm = React.createRef();
    }

    componentDidMount() {
        this.handleScroll();

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { keepRatio, width, src, effect } = this.props;
        const { loaded } = this.state;
        if (!loaded && elementInViewport(this.imgElm.current)) {
            // Load real image
            const imgLoader = new Image();
            imgLoader.src = src;
            imgLoader.onload = () => {
                const ratioWH = imgLoader.width / imgLoader.height;

                if (!isEmpty(this.imgElm.current)) {
                    this.imgElm.current.setAttribute(
                        'src',
                        `${src}`,
                    );

                    keepRatio && this.imgElm.current.setAttribute(
                        'height',
                        `${width / ratioWH}`,
                    );
                    this.imgElm.current.classList.add(`${effect}`);
                }

                this.setState({
                    loaded: true,
                });
            };
        }
    }

    render() {
        const { width, height, alt, className, src } = this.props;
        const cls = `lazy-image ${className}`;
        return (
            <img
                // src={placeHolder}
                src={src}
                width={width}
                height={height}
                // eslint-disable-next-line no-return-assign
                ref={this.imgElm}
                className={cls}
                alt={alt}
            />
        );
    }
}
/* eslint-disable react/no-unused-prop-types,react/require-default-props*/
Imageload.propTypes = {
    className: PropTypes.string,
    placeHolder: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    alt: PropTypes.string,
    effect: PropTypes.string,
};
/* eslint-enable */
export default Imageload;
