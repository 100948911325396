import ReactDOM from 'react-dom';
import React, { PureComponent } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { isEmpty } from 'lodash';
import { tclass } from './index';

export default function notification(type, title, msg, time = 5000) {
    // Random ra 1 className
    const classN = `cls_notifi_${Math.floor((Math.random() * 1000) + 1)}`;
    const g = document.createElement('div');
    g.id = classN;
    document.getElementById(tclass('notification-box')).appendChild(g);
    ReactDOM.render(<NotificationDom
        timeout={time}
        classN={classN}
        msg={msg}
        title={title}
        type={type}
    />, document.getElementById(classN));
}

class NotificationDom extends PureComponent {
    constructor(props) {
        super(props);
        this.timeOut = null;
    }

    componentDidMount() {
        this.setTimes();
    }

    onCloseNoti() {
        this.closeNoti();
    }

    onMouseIn = () => {
        clearTimeout(this.timeOut);
    }

    onMouseOuts = () => {
        this.setTimes();
    }

    setTimes() {
        const { timeout } = this.props;
        if (timeout === 0) return;
        this.timeOut = setTimeout(() => {
            this.closeNoti();
        }, timeout);
    }

    closeNoti() {
        const { classN } = this.props;
        const elid = document.getElementById(classN);
        if (isEmpty(elid)) {
            clearTimeout(this.timeOut);
            return;
        }
        const el = elid.getElementsByClassName(tclass('bounceInRight'))[0];
        el.className = el.className.replace(tclass('bounceInRight'), tclass('bounceOutRight'));
        setTimeout(() => {
            document.getElementById(classN).outerHTML = '';
        }, 300);
    }

    render() {
        const { type, msg } = this.props;
        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName={{ appear: tclass('bounceInRight'), enter: tclass('bounceInRight'), leave: tclass('bounceOutRight') }}
                transitionEnterTimeout={200}
                transitionLeaveTimeout={100}
                onMouseOver={this.onMouseIn}
                onFocus={this.onMouseIn}
                onMouseOut={this.onMouseOuts}
                onBlur={this.onMouseOuts}
                className={tclass('crm_notification animated bounceInRight', {
                    error: type === 'error',
                    success: type === 'success',
                    warning: type === 'warning',
                    info: type === 'info',
                })}
            >
                <div className={tclass('crm_notification_content')}>
                    {/* <div className="crm_notification_content_title">{this.props.title}</div> */}
                    <div className={tclass('crm_notification_content_desc')} dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
                <div className={tclass('crm_notification_btn')} onClick={() => this.onCloseNoti()}>
                    <i className={tclass('icons8-close-button')} aria-hidden="true" />
                    {/* <div>Đóng</div> */}
                </div>
            </ReactCSSTransitionGroup>
        );
    }
}
