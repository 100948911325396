import qs from 'qs';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { URL_API } from '../../constants/config';
import AuthService from '../auth/AuthService';
import { notification } from '../../components/common/library';

function serialize(obj) {
    return `?${Object.keys(obj).reduce((a, k) => { a.push(`${k}=${encodeURIComponent(obj[k])}`); return a; }, []).join('&')}`;
}

export async function GET(url, params) {
    return axios(URL_API + url + serialize(params), {
        method: 'get',
        responseType: 'json',
    }).then(({ data }) => {
        if (data.data !== undefined) {
            return data.data;
        }
        notification('error', 'Dữ liệu không chính xác', 'Dữ liệu không thể xác thực.#01');
        return { errors: { message: 'Dữ liệu không thể xác thực #01.' } };
    }).catch((err) => err);
}

export async function POST(url, params) {
    const Auth = new AuthService();
    const token = await Auth.getToken();
    // const params_s = { ...params };
    try {
        // if (params_s.token === undefined || isEmpty(params_s.token)) {
        //     params_s.token = token;
        // }
        const res = await axios(URL_API + url, {
            method: 'post',
            responseType: 'json',
            headers: {
                authcode: token,
            },
            data: qs.stringify(params),
        });
        if (res.data.data !== undefined) {
            return res.data.data;
        }
    } catch (error) {
        try {
            if (!isEmpty(error) && error.response.status === 403) {
                notification('error', 'Lỗi xác thực', `${error.message} - tài khoản bạn hết hạn đăng nhập hoặc đã có ai đó đang sử dụng tài khoản này!`);
                window.location.href = '/login';
            }
        } catch (e) {
            notification('error', 'Lỗi không xác định', `${error.message}`);
        }
    }
}

export async function POST_OUT(url, params) {
    try {
        const res = await axios(url, {
            method: 'post',
            responseType: 'json',
            data: qs.stringify(params),
        });
        if (res.data.data !== undefined) {
            return res.data.data;
        } else if (res.data !== undefined) {
            return res.data;
        }
    } catch (error) {
        try {
            if (!isEmpty(error) && error.response.status === 403) {
                notification('error', 'Lỗi xác thực', `${error.message} - tài khoản bạn hết hạn đăng nhập hoặc đã có ai đó đang sử dụng tài khoản này!`);
            }
        } catch (e) {
            notification('error', 'Lỗi không xác định', `${error.message}`);
        }
    }
}

export async function UPLOAD(url, params) {
    const Auth = new AuthService();
    const token = await Auth.getToken();
    // const params_s = { ...params };
    try {
        // if (params_s.token === undefined || isEmpty(params_s.token)) {
        //     params_s.token = token;
        // }
        const res = await axios(URL_API + url, {
            method: 'post',
            responseType: 'json',
            headers: {
                authcode: token,
            },
            data: params,
        });
        if (res.data.data !== undefined) {
            return res.data.data;
        }
    } catch (error) {
        if (error.response.status === 403) {
            notification('error', 'Lỗi xác thực', `${error.message} - tài khoản bạn hết hạn đăng nhập hoặc đã có ai đó đang sử dụng tài khoản này!`);
            window.location.href = '/login';
        } else {
            notification('error', 'Lỗi xác thực', `${error.message}`);
        }
    }
}
