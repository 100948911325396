import React, { Component } from 'react';
import md5 from 'locutus/php/strings/md5';
import { isEqual } from 'lodash';
import { tclass } from '../index';
import MenuItem from './MenuItem';
import MenuItemRoot from './MenuItemRoot';

export default class Menus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: props.menu,
            active_link: props.location.pathname,
            theme_color: 'theme_default',
        };
    }

    componentWillReceiveProps(nextProps) {
        const { active_link, theme_color } = this.state;
        if (!isEqual(active_link, nextProps.location.pathname)) this.onClickMenu(nextProps.location.pathname);
        if (theme_color !== nextProps.theme_color) {
            this.setState({ theme_color: nextProps.theme_color, menu: nextProps.menu }, () => {
                this.onClickMenu(nextProps.location.pathname);
            });
        }
    }

    componentDidMount() {
        this.activeMenu();
    }

    showChild = (k) => {
        const { menu } = this.state;
        menu.root_child = menu.root_child.map((v, _k) => {
            const tmp = { ...v };
            tmp.active = false;
            if (_k === k) tmp.active = true;
            return tmp;
        });
        this.setState({ menu });
    }

    onClickMenuProps = (link) => {
        this.onClickMenu(link);
    }

    onClickMenu(link) {
        this.setState({ active_link: link }, () => {
            this.activeMenu();
        });
    }

    activeMenu() {
        const { active_link, menu } = this.state;
        const menus = { ...menu };
        menus.root_child = menus.root_child.map((v) => {
            const tmp_v = { ...v };
            tmp_v.active = false;
            tmp_v.child = tmp_v.child.map((v_child) => {
                const tmp_v_child = { ...v_child };
                tmp_v_child.active = false;
                if (tmp_v_child.link === active_link) {
                    tmp_v_child.active = true;
                    tmp_v.active = true;
                }
                return tmp_v_child;
            });
            return tmp_v;
        });

        this.setState({ menu: menus }, () => {
            const { activeLink } = this.props;
            if (activeLink) activeLink(active_link);
        });
    }

    listMenuChild = (k_parent, menu) => menu.child.map((v, k) => {
        const clsChildItem = `content_menu_child_item_${v.title + k}`;
        if (v.hide !== undefined && v.hide === true) {
            return null;
        }
        return <MenuItem key={clsChildItem} clsChildItem={clsChildItem} item={v} onClickMenu={this.onClickMenuProps} />;
    });

    listMenu(menu) {
        return menu.root_child.map((v, k) => {
            const keys_root = `content_menu_parent_${md5(k)}`;
            return <MenuItemRoot key={k} keys_root={keys_root} showChild={this.showChild} v_item={v} k_item={k} listMenuChild={this.listMenuChild} />;
        });
    }

    render() {
        const { menu } = this.state;

        return (
            <React.Fragment>
                <div className={tclass('st_left_menu')}>
                    <div className="content_menu">
                        <div className="content_menu_title">{menu.root_title}</div>
                        <div className="content_menu_list">
                            {this.listMenu(menu)}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
