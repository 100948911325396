
import { Map, OrderedMap, Set, is } from 'immutable';
import { isArray as _isArray } from 'lodash';
import * as Types from '../actions';
import * as operators from '../../components/common/library/filter_common/operators';

let nextId = 0;

const emptyFilters = OrderedMap();
// const availableFields = Set([fields.name, fields.age, fields.dob, fields.married, fields.country]);
const availableFields = Set([]);

const initialState = Map({
    filters: emptyFilters,
    selectedFields: availableFields,
    availableFields,
});

function addDataFilter(state, data) {
    return state.set('availableFields', Set(data)).set('selectedFields', Set(data));
}
function selectField(state, field) {
    return state.update('selectedFields', (fields) => fields.add(field));
}

function unselectField(state, field) {
    const selectedFields = state.get('selectedFields');
    const filterWithRemovedField = (filter) => is(filter.get('field'), field);

    if (selectedFields.size === 1) {
        return state;
    }

    return state
        .update('filters', (filters) => filters.filterNot(filterWithRemovedField))
        .set('selectedFields', selectedFields.delete(field));
}

function getFilter(field) {
    const { defaultOperator, defaultValue } = field.widget;
    return Map({ field, operator: defaultOperator, value: defaultValue });
}

function addFilter(state) {
    return state.update('filters', (filters) => filters.set(++nextId, getFilter(state.get('selectedFields').first())));
}

function removeFilter(state, filterId) {
    return state.deleteIn(['filters', filterId]);
}

function clearFilters(state) {
    return state.set('filters', emptyFilters);
}

function changeFilterField(state, filterId, fieldName) {
    return state.setIn(['filters', filterId], getFilter(state.get('availableFields').find((o) => o.name === fieldName)));
}

function getValue(value, operator) {
    const isArray = _isArray(value);
    if (operator === operators.BETWEEN || operator === operators.NOT_BETWEEN) {
        return isArray ? value : [value, null];
    }
    return isArray ? value[0] : value; //Phần này là chọn lại thì nó sẽ là chọn cái thứ 1
}

function changeFilterOperator(state, filterId, operator) {
    return state.updateIn(['filters', filterId], (filter) => filter
        .set('operator', operator)
        .set('value', getValue(filter.get('value'), operator)));
}

function changeFilterValue(state, filterId, value) {
    return state.setIn(['filters', filterId, 'value'], value);
}

const routerReducersFilter = (state = initialState, { type, field, filterId, fieldName, operator, value } = {}) => {
    let states = state;
    switch (type) {
        case Types.SELECT_FIELD:
            states = selectField(state, field); break;

        case Types.UNSELECT_FIELD:
            states = unselectField(state, field); break;

        case Types.ADD_FILTER:
            states = addFilter(state); break;

        case Types.REMOVE_FILTER:
            states = removeFilter(state, filterId); break;

        case Types.CLEAR_FILTERS:
            states = clearFilters(state); break;

        case Types.CHANGE_FILTER_FIELD:
            states = changeFilterField(state, filterId, fieldName); break;

        case Types.CHANGE_FILTER_OPERATOR:
            states = changeFilterOperator(state, filterId, operator); break;

        case Types.CHANGE_FILTER_VALUE:
            states = changeFilterValue(state, filterId, value); break;

        case Types.DATA_FILTER:
            states = addDataFilter(state, value); break;

        default:
            states = state; break;
    }
    return states;
};
export default routerReducersFilter;
