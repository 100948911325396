import * as Types from '../actions';

const initialState = {
    total_online: 0,
    customer_online: {},
    tags: [],
    register: {
        email: { value: '', validate: true, msg: null },
        phone: { value: '', validate: true, msg: null },
        password: { value: '', validate: true, msg: null },
        cf_password: { value: '', validate: true, msg: null },
        social: '',
        social_id: '',
        avatar: '',
        social_name: '',
        access_token: '',
    },
    //History Check
    resetHistory: false,
    //
    chat_list: {},
    chat_list_item: {},
    chat_get_info: {},
    boxchat_setting_info: {},
    //Fix
    customer_current: {
        full_name: { value: '', validate: true, msg: null },
        phone: { value: '', validate: true, msg: null },
        mobile_phone: { value: '', validate: true, msg: null },
        email: { value: '', validate: true, msg: null },
        address: { value: '', validate: true, msg: null },
    },
    departments: [],
    personnels: [],
    category: [],
    customer: [],
    properties: [],
    branchs_list: {},
    get_setting: {},

    supplier: [],
    units: [],
    bankaccount: [],
    typereceipts: [],
    //Forms add product
    //Xử lý TMP FORM

    /*--------------Form thêm sản phẩm-------------*/
    form_product: {
        //Những giá trị áp dụng được ngay
        name: { value: '', validate: true, msg: null },
        sku: { value: '', validate: true, msg: null },
        description: { value: '', validate: true, msg: null },
        note_order: { value: '', validate: true, msg: null },
        manufacturer_id: { value: 0, validate: true, msg: null },
        inventory_min: { value: 0, validate: true, msg: null },
        inventory_max: { value: 999999999, validate: true, msg: null },
        category_id: { value: 0 },
        branch_id: { value: [], validate: true, msg: null },
        status: { value: 1 },
        barcode: { value: '', validate: true, msg: null },
        guarantee: { value: [], validate: true, msg: null },
        //Những giá trị áp dụng được ngay
        image: { value: [] },
        //Giá cả các thứ
        price: { value: 0, value_format: 0, validate: true, msg: null },
        price_market: { value: 0, value_format: 0, validate: true, msg: null },
        price_wholesale: { value: 0, value_format: 0, validate: true, msg: null },

        //Số lượng tồn kho
        quantity: { value: 0, value_format: 0, validate: true, msg: null },

        //Kho hàng
        depot_id: { value: [], validate: true, msg: null },

        //Ghi chú

        //tích điểm
        is_point: false,
    },
    /*------Khu vực xử lý logic sâu------*/
    form_product_logic: {
        properties_row: [],
        properties: [],
        units: [],
    },

    /*------Chương trình khuyến mại------*/
    promotion: {
        info: {
            name: '',
            code: '',
            status: 1,
        },
        area: {
            depot_type: 'all',
            depot_value: [],
            seller_type: 'all',
            seller_value: [],
            customer_type: 'all',
            customer_value: [],
        },
        times: {
            date_start: null,
            date_end: null,
            month: [],
            day: [],
            day_week: [],
            hour: [],
            birthday: 'none',
        },
        condition: {
            con_type: 'bill',
            con_method: 'Giamgia_theohd',
            con_by_quantity: 0, //Có nhân theo số lượng hay không
            condition_list: [],
        },
        coupon: {
            coupon_total: 1, //Tối đa 2000
            coupon_charter: 4,
            coupon_perfix_first: '',
            coupon_perfix_last: '',
            coupon_use: 1, //Số lượt sử dụng
            coupon_code: [],
        },
    },

    /*--------------Dữ liệu sử dụng lựa chọn-------------*/
    p_base: {
        units_child: [],
    },

    /*--------------Form thêm sản phẩm-------------*/
    form_customer: {
        customer_id: { value: '', validate: true, msg: null },
        full_name: { value: '', validate: true, msg: null },
        mobile_phone: { value: '', validate: true, msg: null },
        email: { value: '', validate: true, msg: null },
        address: { value: '', validate: true, msg: null },
        province_id: { value: 0, validate: true, msg: null },
        district_id: { value: 0, validate: true, msg: null },
        address_id: { value: 0, validate: true, msg: null },
        gender: { value: 1, validate: true, msg: null },
        birthday: { value: '', validate: true, msg: null },
        avatar: { value: '', validate: true, msg: null },
        position: { value: '', validate: true, msg: null },
    },

    /*--------------Form thêm khách hàng-------------*/
    info_customer: {
        customer_id: { value: 0, validate: true, msg: null },
        full_name: { value: '', validate: true, msg: null },
        mobile_phone: { value: '', validate: true, msg: null },
        email: { value: '', validate: true, msg: null },
        address: { value: '', validate: true, msg: null },
        province_id: { value: 0, validate: true, msg: null },
        district_id: { value: 0, validate: true, msg: null },
        ward_id: { value: 0, validate: true, msg: null },
        address_id: { value: 0, validate: true, msg: null },
        gender: { value: 1, validate: true, msg: null },
        birthday: { value: '', validate: true, msg: null },
        avatar: { value: '', validate: true, msg: null },
        position: { value: '', validate: true, msg: null },
        type: { value: 1, validate: true, msg: null },
        debt: [],
    },
     /*-------------- Form cài đặt sms -------------*/
    setting_sms: {
        status: { value: 1, validate: true, msg: null },
        order_status: { value: 1, validate: true, msg: null },
        optionsCategory: { value: '', validate: true, msg: null },
        time_delay: { value: 30, validate: true, msg: null },
        content: { value: '', validate: true, msg: null },
        id: { value: 0, validate: true, msg: null },
        product_id: { value: '', validate: true, msg: null },
        product_name: { value: '', validate: true, msg: null },
        day: { value: 1, validate: true, msg: null },
        
    },

     /*-------------- Form cài đặt email -------------*/
     setting_email: {
        status: { value: 1, validate: true, msg: null },
        order_status: { value: 1, validate: true, msg: null },
        optionsCategory: { value: '', validate: true, msg: null },
        number_day: { value: 10, validate: true, msg: null },
        time_delay: { value: 10, validate: true, msg: null },
        content: { value: '', validate: true, msg: null },
        title: { value: '', validate: true, msg: null },
        id: { value: 0, validate: true, msg: null },
        product_id: { value: '', validate: true, msg: null },
        product_name: { value: '', validate: true, msg: null },
        mail_cc: { value: '', validate: true, msg: null },
        mail_bcc: { value: '', validate: true, msg: null },
        id_design_email: { value: '', validate: true, msg: null },
        
    },
    /*-------------- Sản phẩm Sendo -------------*/
    productSendo: {
        result: '',
        total: '',
        connect: '',
        totalconnect: '',
    },
    productSync: {
        result: '',
    },
    clientSendo: {
        result: '',
        name_sendo: '',
        depot: '',
        data_sendo: '',
    },

    /*-------------- Sản phẩm Lazada -------------*/
    productLazada: {
        result: '',
        total: '',
        connect: '',
        totalconnect: '',
    },
    productSyncLazada: {
        result: '',
    },
    clientLazada: {
        result: '',
    },
};

const routerReducers = (state = initialState, { type, payload } = {}) => {
    let states = state;
    switch (type) {
        case Types.R_TOTAL_ONLINE:
            states = { ...state, customer_online: payload, total_online: payload }; break;

        case Types.R_TAG:
            states = { ...state, tags: payload }; break;

        case Types.R_REGISTER:
            states = { ...state, register: payload }; break;

        case Types.R_CHAT_LIST:
            states = { ...state, chat_list: payload }; break;

        case Types.R_CHAT_LIST_ITEM:
            states = { ...state, chat_list_item: payload }; break;

        case Types.R_CHAT_GET_INFO:
            states = { ...state, chat_get_info: payload }; break;

        case Types.R_BOXCHAT_SETTING_INFO:
            states = { ...state, boxchat_setting_info: payload }; break;

        case Types.R_CUSTOMER_CURRENT:
            states = { ...state, customer_current: payload }; break;

        case Types.R_BRANCHS_LIST:
            states = { ...state, branchs_list: payload }; break;

        case Types.R_GET_SETTING:
            states = { ...state, get_setting: payload }; break;

        case Types.R_DEPARTMENT:
            states = { ...state, departments: payload }; break;

        case Types.R_PERSONNELS:
            states = { ...state, personnels: payload }; break;

        case Types.R_CATEGORY_LIST:
            states = { ...state, category: payload }; break;

        case Types.R_PROPERTIES_LIST:
            states = { ...state, properties: payload }; break;

        case Types.R_FORM_PRODUCT:
            states = { ...state, form_product: payload }; break;

        case Types.R_FORM_CUSTOMER:
            states = { ...state, form_customer: payload }; break;

        case Types.R_SUPPLIER:
            states = { ...state, supplier: payload }; break;

        case Types.R_CUSTOMER:
            states = { ...state, customer: payload }; break;

        case Types.R_UNITS_LIST:
            states = { ...state, units: payload }; break;

        case Types.R_BANK_ACCOUNT_LIST:
            states = { ...state, bankaccount: payload }; break;

        case Types.R_TYPE_RECEIPTS:
            states = { ...state, typereceipts: payload }; break;

        case Types.R_FORM_PRODUCT_LOGIC:
            states = { ...state, form_product_logic: payload }; break;

        case Types.R_PBASE:
            states = { ...state, p_base: payload }; break;

        case Types.R_CUSTOMER_FORM:
            states = { ...state, info_customer: payload }; break;

        case Types.R_SETTING_SMS:
            states = { ...state, setting_sms: payload }; break;

        case Types.R_SETTING_EMAIL:
        states = { ...state, setting_email: payload }; break;

        case Types.R_PRODUCT_SENDO:
        states = { ...state, productSendo: payload }; break;

        case Types.R_CLIENT_SENDO:
        states = { ...state, clientSendo: payload }; break;

        case Types.R_SYNC_SENDO:
        states = { ...state, productSync: payload }; break;

        case Types.R_PRODUCT_LAZADA:
        states = { ...state, productLazada: payload }; break;

        case Types.R_CLIENT_LAZADA:
        states = { ...state, clientLazada: payload }; break;

        case Types.R_SYNC_LAZADA:
        states = { ...state, productSyncLazada: payload }; break;

        case Types.R_PROMOTION:
            states = { ...state, promotion: payload }; break;

        case Types.R_HISTORY:
            states = { ...state, resetHistory: payload }; break;

        default:
            states = state;
            break;
    }
    return states;
};

export default routerReducers;
