import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { tclass, Link, Delayed } from './index';
import Menus from './content/Menus';


class Content extends React.Component {
    static defaultProps = {
        toggle_full: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            toggle: props.toggle_full,
            active_link: null,
        };
        this.myRef = React.createRef();
        this.box_component_loaded = [];
    }

    activeLink = (link) => {
        this.setState({ active_link: link });
    }

    componentDidUpdate() {
        setTimeout(() => {
            try {
                this.myRef.current.classList.remove('st_body_content_animation_pop');
            } catch (e) { }
        }, 100);
    }

    toggleMenu = () => {
        const { toggle } = this.state;
        this.setState({ toggle: !toggle });
    }

    componentList(active_link) {
        const { menu } = this.props;
        const { toggle } = this.state;
        //Tìm kiếm xem nó thuộc component nào
        let parent_item_active = null;
        let item_active = null;
        menu.root_child.forEach((v) => {
            v.child.forEach((v_child) => {
                if (v_child.link === active_link) {
                    item_active = v_child;
                    parent_item_active = v;
                }
            });
        });
        const tmp_item = {
            key: item_active.key,
            parent_title: parent_item_active.title,
            full: parent_item_active.full || false,
            item: item_active,
        };
        return (
            <div ref={this.myRef} className={tclass('st_body_content scroll_bar', { full: toggle, st_body_content_animation_pop: true })}>
                <div className={tclass('title_menu')}>
                    <div className="icon" onClick={this.toggleMenu}>
                        <i className={tclass('icons8-right-pointing-arrow ic_menu_toggle', { ic_menu_toggle_back: toggle })} />
                    </div>
                    <div className="title">
                        {tmp_item.parent_title} / <Link to={tmp_item.item.link}>{tmp_item.item.title.toLowerCase()}</Link>
                    </div>
                </div>
                <div className={tclass('st_body_content_child', { full: tmp_item.item.full })}>
                    <Delayed waitBeforeShow={200}>
                        {tmp_item.item.component}
                    </Delayed>
                </div>
            </div>
        );
    }

    render() {
        const { active_link } = this.state;
        return (
            <React.Fragment>
                <div className="st_body">
                    <Menus {...this.props} activeLink={this.activeLink} />
                    {active_link !== null
                        ? this.componentList(active_link)
                        : <div>Đang nhận dữ liệu</div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

Content.propTypes = {
    toggle_full: PropTypes.bool,
};

export default withRouter(Content);
