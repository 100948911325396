import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { whilst } from 'async';
import { Spin } from './index';

class Ckeditor extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.myRefToolbar = React.createRef();
        this.CKEDITOR = window.CKEDITOR;
        this.editorInstance = null;
        this.state = {
            content: '',
            loading: true,
        };
    }

    static defaultProps = {
        options: [],
        onChange: () => { },
        value: '',
        type: 'classic',
    }

    componentWillMount() {
        const self = this;
        let j = 0;
        whilst(
            () => j < 10,
            (callback) => {
                j++;
                if (self.editorInstance === null) {
                    setTimeout(() => {
                        callback(null, j);
                    }, 500);
                } else {
                    callback(null, j);
                }
            },
            () => {
                self.setState({ loading: false });
            },
        );
    }

    componentWillReceiveProps(nextProps) {
        const { value } = nextProps;
        const { content } = this.state;
        if (!isEqual(content, value)) {
            this.setState({ content: value }, () => {
                this.editorInstance.setData(value);
            });
        }
    }

    componentDidMount() {
        this.editorInstance = this.CKEDITOR.replace(this.myRef.current, {
            // extraPlugins: 'base64image',
            // removePlugins: 'image',
            // // removeDialogTabs: 'link:advanced',
            // toolbar: [
            //     { name: 'document', items: ['Source', '-'] },
            //     // ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
            //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike'] },
            //     { name: 'insert', items: ['base64image', 'Table', 'HorizontalRule', 'PageBreak'] },
            //     { name: 'colors', items: ['TextColor', 'BGColor'] },
            //     { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
            //     { name: 'styles', items: ['Styles', 'FontSize', 'lineheight'] },
            // ],
        });
        this.listenEventChange(this.editorInstance);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { loading } = this.state;
        if (nextState.loading !== loading) {
            return true;
        }
        return false;
    }


    listenEventChange(editor) {
        const { onChange } = this.props;
        editor.on('change', () => {
            const data = editor.getData();
            this.setState({ content: data });
            if (onChange) onChange(data);
        });
    }

    render() {
        const { value } = this.props;
        const { loading } = this.state;
        return (
            <Spin spin={loading}>
                <div className="ck_editor" style={{ overflow: 'hidden' }}>
                    <textarea value={value} ref={this.myRef} />
                </div>
            </Spin>
        );
    }
}

/* eslint-disable*/
Ckeditor.propTypes = {
    options: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.string,
    type: PropTypes.string
};
/* eslint-enable */

export default Ckeditor;
