import React, { PureComponent } from 'react';
import * as operators from '../filter_common/operators';

class BooleanWidget extends PureComponent {
  render() {
    const { value, onValueChange } = this.props;
    return (
      <label>
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => onValueChange(e.target.checked)}
        />
        Có
      </label>
    );
  }
}

BooleanWidget.defaultOperator = operators.EQUALS;
BooleanWidget.defaultValue = false;

export default BooleanWidget;
