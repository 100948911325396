import React, { Component } from 'react';
import { connect } from 'react-redux';
import waterfall from 'async/waterfall';
import { Button, tclass, Spin, Input, notification } from '../../common/library';
import { updateInfoApi, upload_api } from '../../../services/api/fetch';
import UploadImage from './UploadImage';
import AuthService from '../../../core/auth/AuthService';

import { R_PROFILE } from '../../../reducers/actions';

const auth = new AuthService();
class General extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            check: true,
            forms: {
                id: { value: props.profile.personnel_id, validate: true, msg: null },
                full_name: { value: props.profile.full_name, validate: true, msg: null },
                birthday: { value: props.profile.birthday, validate: true, msg: null },
                gender: { value: props.profile.gender, validate: true, msg: null },
                avatar: { value: props.profile.photo, validate: true, msg: null },
                mobile_number: { value: props.profile.mobile_number, validate: true, msg: null },
                email_private: { value: props.profile.email_private, validate: true, msg: null },
            },
        };
    }

    onChangeInput(type, e) {
        const { forms } = this.state;
        const { value } = e.target;
        forms[type].value = value;
        forms[type].validate = true;
        this.setState({ forms });
    }

    onUpload = (files) => {
        const self = this;
        const { forms } = this.state;
        const { profile, dispatch } = this.props;
        const data = new FormData();
        data.append('file_upload', files);
        data.append('mtype', 'upload');
        self.setState({ loading: true });
        upload_api(data).then(({ message, status }) => {
            self.setState({ loading: false });
            if (status) {
                forms.avatar.value = message;
                this.setState({ forms });
                const profile_clone = Object.assign({}, profile);
                profile_clone.photo = message;
                dispatch({ type: R_PROFILE, payload: profile_clone });
            } else {
                notification('error', 'Lỗi', message);
            }
        });
    }

    submitForm = () => {
        const self = this;
        const { forms } = this.state;
        waterfall([
            (callback) => {
                if (forms.full_name.value.length === 0) {
                    forms.full_name.validate = false;
                    forms.full_name.msg = 'Họ tên không được để trống';
                } else {
                    forms.full_name.validate = true;
                }
                self.setState({ forms }, () => {
                    if (forms.full_name.validate) callback(null, 'next');
                });
            },
        ], () => {
            const params = {
                full_name: forms.full_name.value,
                avatar: forms.avatar.value,
                user_id: forms.id.value,
                mtype: 'update',
            };
            self.updateInfo(params);
        });
    }

    updateInfo(params) {
        const { dispatch } = this.props;
        this.setState({ loading: true });
        updateInfoApi(params).then(() => {
            notification('success', '', 'Thay đổi thông tin thành công');
            auth.getInfo({
                mtype: 'profile_info',
            }).then((user_info) => {
                this.setState({ loading: false });
                dispatch({ type: R_PROFILE, payload: user_info });
            });
        });
    }

    render() {
        const { loading, forms, check } = this.state;
        return (
            <React.Fragment>
                <Spin spin={loading}>
                    <div className={tclass('setting_profile')}>
                        <div className={tclass('row')}>
                            <div className={tclass('col-md-8 col-md-8 col-xs-7 full-xs')}>
                                <Input
                                    onChange={(e) => this.onChangeInput('full_name', e)}
                                    className={tclass('form-control')}
                                    label="Họ tên"
                                    placeholder="Họ tên"
                                    //ic_left="la la-key"
                                    error={forms.full_name.validate === false ? forms.full_name.msg : ''}
                                    value={forms.full_name.value}
                                    require
                                />
                                <Input
                                    onChange={(e) => this.onChangeInput('mobile_number', e)}
                                    className={tclass('form-control')}
                                    label="Di động"
                                    placeholder="Di động"
                                    //ic_left="la la-phone"
                                    error={forms.mobile_number.validate === false ? forms.mobile_number.msg : ''}
                                    value={forms.mobile_number.value}
                                    disabled={check}
                                    require
                                />
                                <Input
                                    onChange={(e) => this.onChangeInput('email_private', e)}
                                    className={tclass('form-control')}
                                    label="Email"
                                    placeholder="Email"
                                    //ic_left="la la-envelope"
                                    error={forms.email_private.validate === false ? forms.email_private.msg : ''}
                                    value={forms.email_private.value}
                                    disabled={check}
                                    require
                                />
                            </div>
                            <div className={tclass('col-md-4 col-sm-4 col-xs-5 full-xs margin-top-15-xs')}>
                                <div className={tclass('load_address_item')}>
                                    <label>Ảnh đại diện</label>
                                    <UploadImage {...this.props} onUpload={this.onUpload} />
                                </div>
                            </div>
                            <Button
                                onClick={() => this.submitForm()}
                                className={tclass('btn btn-primary')}
                            >
                                Cập nhật
                            </Button>
                        </div>
                    </div>
                </Spin>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ persist }) => ({
    profile: persist.profile,
});
export default connect(mapStateToProps)(General);
