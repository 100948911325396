import React, { PureComponent } from 'react';
import { Modal } from '../../common/library';
import AuthService from '../../../core/auth/AuthService';

const auth = new AuthService();

export default class Logout extends PureComponent {
    onVisibleLocal() {
        const { onVisible1 } = this.props;
        if (onVisible1) onVisible1();
    }

    submitForm() {
        auth.logout(() => {
            window.location.href = '/login';
        });
    }

    render() {
        const { visible1 } = this.props;
        return (
            <React.Fragment>
                <div>
                    <Modal
                        title="Đăng xuất ?"
                        visible={visible1}
                        onOk={() => this.submitForm()}
                        onCancel={() => this.onVisibleLocal()}
                        txtCancel="Huỷ"
                        txtOk="Đăng xuất"
                        size="sm"
                    >
                        <div>
                            <p>Tất cả thông tin liên quan tới tài khoản sẽ bị hủy trên máy tính này.</p>
                            <p>Bạn có chắc chắn muốn đăng xuất ?</p>
                        </div>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}
