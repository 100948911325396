import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TYPE } from '../constants/config';
import { routerReducers, routerReducersStatic, routerReducersFilter } from '../reducers';
import rootSaga from '../saga';

const composeEnhancers = compose;

const config = { key: `bpos_${TYPE}`, storage };

const sagaMiddleware = createSagaMiddleware();

const reducersStorage = combineReducers({
    state: routerReducers,
    state_filter: routerReducersFilter,
    persist: persistReducer(config, routerReducersStatic),
});
/* eslint-disable no-underscore-dangle */
// const composeEnhancers = typeof window === 'object'
//     && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     }) : compose;
/* eslint-enable */

const store = createStore(
    reducersStorage,
    composeEnhancers(applyMiddleware(sagaMiddleware), applyMiddleware(thunk)),
);
sagaMiddleware.run(rootSaga);


const persistor = persistStore(store, null, () => { });
export { persistor, store };
