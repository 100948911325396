import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { tclass } from './index';

class Tabs extends PureComponent {
    constructor(props) {
        super(props);
        const id_rand = Math.floor((Math.random() * 10000) + 1);
        this.idKey = `nxt_tab_${id_rand}`;
        this.idTab = `nxt_tab_key_${id_rand}`;
    }

    activeTab(k) {
        //Không render lại
        const childs = document.getElementById(this.idKey).children;
        const childsTab = document.getElementById(this.idTab).children;
        let i = 0;
        for (i; i < childs.length; i++) {
            childs[i].classList.remove('in', 'active');
            if (childs[i].getAttribute('data-key') === k) {
                childs[i].classList.add('in', 'active');
            }

            childsTab[i].classList.remove('active');
            if (childsTab[i].getAttribute('data-key') === k) {
                childsTab[i].classList.add('active');
            }
        }
    }

    /* eslint-disable*/
    renderListTab() {
        const { source } = this.props;
        const htm = source.map((v, k) => {
            return (<li key={v.key} data-key={v.key} className={tclass({ active: v.key === this.props.active })} onClick={() => this.activeTab(v.key)}><a href="#">{v.title}</a></li>);
        });
        return htm;
    }
    /* eslint-enable */

    renderChild() {
        const { active, source } = this.props;
        const htm = source.map((v) => (
            <div key={v.key} data-key={v.key} className={tclass('tab-pane fade', { active: v.key === active })}>
                {v.component}
            </div>
        ));
        return htm;
    }

    render() {
        return (
            <React.Fragment>
                <ul className="nav nav-tabs" id={this.idTab}>
                    {this.renderListTab()}
                </ul>
                <div className="tab-content" id={this.idKey}>
                    {this.renderChild()}
                </div>
            </React.Fragment>
        );
    }
}

/* eslint-disable*/
Tabs.propTypes = {
    source: PropTypes.array.isRequired,
    active: PropTypes.string.isRequired,
};
/* eslint-enable */
export default Tabs;
