import React from 'react';
import { find } from 'lodash';
import { Link } from '../library';

const NavigationLi = (props) => {
    const { url, title, userPermission, userRole, not_li } = props;
    let htm_lock = null;
    if (find(userRole, (o) => o.role_id === 1)) {
        htm_lock = null;
    } else if (!find(userPermission, (o) => o.url_frontend === url)) {
        htm_lock = (<i className="la la-lock" />);
    }

    //Nếu trường hợp có quyền nhân viên bán hàng thì sẽ bỏ khóa chữ bán hàng
    if (find(userRole, (o) => o.role_id === 2) && url === '/point-of-sale') {
        htm_lock = null;
    }

    return (
        <React.Fragment>
            {not_li === true ? (
                <Link to={url}>
                    {htm_lock}
                    {title}
                </Link>
            ) : (
                <li>
                    <Link to={ url }>
                        { htm_lock }
                        { title }
                    </Link>
                </li>
            )}
        </React.Fragment>
    );
};

export default React.memo(NavigationLi);
