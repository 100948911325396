import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { tclass } from './index';

class Spin extends PureComponent {
    static defaultProps = {
        spin: false,
        txt: 'Vui lòng đợi giây lát...',
    }

    render() {
        const { children, spin, txt } = this.props;
        return (
            <React.Fragment>
                <div className={tclass('spin')}>
                    {children}
                    <div className={tclass('overlay', {
                        hide: spin !== true,
                    })}
                    >
                        <div className={tclass('overlay_icon')}>
                            <div className={tclass('loader')} />
                            <div className={tclass('txt')}>{txt}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Spin.propTypes = {
    spin: PropTypes.bool,
    txt: PropTypes.string,
};

export default Spin;
