import React, { Component, createElement } from 'react';
import { is } from 'immutable';
import { Select } from '../index';

export default class FilterItem extends Component {
  shouldComponentUpdate(nextProps) {
    const { filter, availableFields } = this.props;
    return !is(nextProps.filter, filter) || !is(nextProps.availableFields, availableFields);
  }

  renderAvailable() {
    const { availableFields, onFieldChange, filter } = this.props;
    return (
      <Select
        value={filter.get('field').name}
        onChange={(e) => onFieldChange(e.value)}
        options={this.renderField(availableFields)}
        placeholder="--Lựa chọn--"
      // isSearchable
      />
    );
  }

  renderField(availableFields) {
    const options = [];
    availableFields.forEach(({ name, displayName }) => {
      const tmp = { value: name, label: displayName };
      options.push(tmp);
    });
    return options;
  }

  renderWidget() {
    const { filter, onOperatorChange, onValueChange } = this.props;
    const field = filter.get('field');
    const props = Object.assign({
      operator: filter.get('operator'),
      value: filter.get('value'),
      onOperatorChange,
      onValueChange,
    }, field.widgetOptions);

    return createElement(field.widget, props);
  }

  render() {
    const { onRemoveClick } = this.props;
    return (
      <li className="filter-item">
        <div className="row">
          <div className="col-md-1 col-sm-1 col-xs-12">
            <button type="button" className="remove-button btn btn-primary" onClick={onRemoveClick}><i className="la la-times" /> xóa</button>
          </div>
          <div className="col-md-2 col-sm-2 col-xs-12">
            {this.renderAvailable()}
          </div>
          <div className="col-md-9 col-sm-9 col-xs-12">
            {this.renderWidget()}
          </div>
        </div>
      </li>
    );
  }
}
