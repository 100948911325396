import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, omit, replace } from 'lodash';
import { intval } from 'locutus/php/var';
import { Tooltip, tclass, FormatPrice } from './index';


class Input extends PureComponent {
    static defaultProps = {
        require: false,
        format: 'text',
        getRef: null,
        readonly: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            err: props.error,
        };
        if (props.getRef !== null) this.myRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.error !== prevState.err) {
            return { err: nextProps.error };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.getRef !== null) this.props.getRef(this.myRef);
    }

    onChangeLocal = (e) => {
        let { value } = e.target;

        if (this.props.format === 'price') {
            if (isEmpty(value)) value = 0;
            e.target.value_format = FormatPrice(value);
            e.target.value_number = this.format_number(value);
        } else if (this.props.format === 'quantity') {
            if (isEmpty(value)) value = 0;
            //Kiểm tra xem nó có mấy ký tự.
            e.target.value_format = this.ceilCustom(value);
            e.target.value_number = this.ceilCustom(value);
            e.target.value = this.ceilCustom(value);
        }
        if (this.props.onChange) this.props.onChange(e);
    }

    format_number(string) {
        return intval(replace(string, /\./g, ''));
    }

    //Làm tròn số đến hàng thứ 2
    ceilCustom(float_numss) {
        let float_num = float_numss;
        const n = parseFloat(float_numss);
        float_num = Math.round(n * 100) / 100;
        return float_num;
    }

    /* eslint-enable */

    render() {
        const { err } = this.state;
        const propss = { ...this.props };

        if (!isEmpty(propss.ic_right)) propss.className = tclass([propss.className, 'ic_right']);
        if (!isEmpty(propss.ic_left)) propss.className = tclass([propss.className, 'ic_left']);
        //Nếu trường hợp  có format
        if (propss.format === 'price') propss.value = FormatPrice(propss.value);
        if (propss.format === 'quantity') {
            propss.value = this.ceilCustom(propss.value) || 0;
        }
        if (propss.value === null) {
            propss.value = '';
        }
        return (
            <React.Fragment>
                <div className={tclass('form-group')}>

                    {!isEmpty(propss.label)
                        && <label>{propss.label} {propss.require === true && <span className="color_require">*</span>}</label>
                    }

                    <div className={tclass('input-content')}>
                        {propss.getRef !== null
                            ? <input {...omit(propss, ['require', 'onChange', 'value', 'ref', 'getRef', 'readonly'])} readOnly={propss.readonly} ref={this.myRef} value={propss.value} onChange={this.onChangeLocal} />
                            : <input {...omit(propss, ['require', 'onChange', 'value', 'ref', 'getRef', 'readonly'])} readOnly={propss.readonly} value={propss.value} onChange={this.onChangeLocal} />
                        }
                        {!isEmpty(propss.ic_right)
                            && <React.Fragment>
                                {isEmpty(propss.ic_tooltip)
                                    ? (
                                        <i
                                            className={tclass(propss.ic_right, {
                                                icon: true,
                                                icon_right: !isEmpty(propss.ic_right),
                                            })}
                                            aria-hidden="true"
                                        />
                                    )

                                    : (
                                        <Tooltip content={propss.ic_tooltip}>
                                            <i
                                                className={tclass(propss.ic_right, {
                                                    icon: true,
                                                    icon_right: !isEmpty(propss.ic_right),
                                                })}
                                                aria-hidden="true"
                                            />
                                        </Tooltip>
                                    )
                                }
                            </React.Fragment>
                        }
                        {!isEmpty(propss.ic_left)
                            && <React.Fragment>
                                {isEmpty(propss.ic_tooltip)
                                    ? (
                                        <div className={tclass('icon_input_left')}>
                                            <i className={tclass(propss.ic_left, {
                                                icon: true,
                                                icon_left: !isEmpty(propss.ic_left),
                                            })}
                                            />
                                        </div>
                                    )

                                    : (
                                        <Tooltip content={propss.ic_tooltip}>
                                            <div className={tclass('icon_input_left')}>
                                                <i className={tclass(propss.ic_left, {
                                                    icon: true,
                                                    icon_left: !isEmpty(propss.ic_left),
                                                })}
                                                />
                                            </div>
                                        </Tooltip>
                                    )
                                }
                            </React.Fragment>
                        }
                    </div>
                    {!isEmpty(err)
                        && <p className={tclass('show-error')}>{err}</p>
                    }
                </div>
            </React.Fragment>
        );
    }
}
/* eslint-disable react/no-unused-prop-types,react/require-default-props*/
Input.propTypes = {
    className: PropTypes.string,
    ic_right: PropTypes.string,
    ic_left: PropTypes.string,
    ic_tooltip: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    require: PropTypes.bool,
    readonly: PropTypes.bool,
    format: PropTypes.string,
    onChange: PropTypes.func,
    getRef: PropTypes.func,
};
/* eslint-enable */

export default Input;
