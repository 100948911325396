import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import DefaultWidget from './DefaultWidget';
import * as operators from '../filter_common/operators';

class DateWidget extends DefaultWidget {
  getOperators() {
    const { operators_use } = this.props;
    const op = {};
    operators_use.forEach((v) => {
      op[v] = operators.NAME[v];
    });
    return op;
  }

  renderInputElement(value, onChange) {
    // eslint-disable-next-line prefer-const
    let { between_date } = this.props;
    if (between_date === undefined) between_date = 'DD/MM/YYYY';
    const dateAsString = !isEmpty(value) ? moment(value) : moment(new Date());
    const onChangeWithDate = (e) => {
      onChange(e.format('YYYY-MM-DD HH:mm'));
    };
    const regex = new RegExp(/(HH:mm)$/mig);
    const showTimeSelect = regex.test(between_date);
    return (
      <div className="form-group">
        <DatePicker
          className="form-control col-md-3"
          onChange={onChangeWithDate}
          selected={dateAsString}
          showTimeSelect={showTimeSelect}
          showYearDropdown
          showMonthDropdown
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat={between_date}
          shouldCloseOnSelect
        />
      </div>
    );
  }
}

DateWidget.defaultOperator = operators.EQUALS;
DateWidget.defaultValue = new Date();

export default DateWidget;
